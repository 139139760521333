import React, { useCallback, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';

type quizButtonViewProps = {
    onPress: (value: string) => void;
    disabled?: boolean;
    text?: string;
    textStyles?: string;
    value: string;
    multiselect: boolean;
    icon?: string;
};
type quizButtonViewState = {
    isActive: boolean;
};

const QuizButtonView: React.FC<quizButtonViewProps> = ({
  onPress,
  disabled,
  text,
  textStyles,
  value,
  icon,
  multiselect,
}) => {
  const [getState, setState] = useState<quizButtonViewState>({
    isActive: false,
  });
  const onButtonPress = useCallback(() => {
    onPress && onPress(value);
    if (multiselect) {
      setState({ ...getState, isActive: !getState.isActive });
    }
  }, [getState, multiselect, onPress, value]);
  const pathToIcon = icon && icon.length > 10 ? require(`../../../assets/quiz/${icon}`) : icon;
  return (
    <button className={`quiz-answers-item ${getState.isActive ? 'quiz-answers-item__active' : ''}`} onClick={onButtonPress} disabled={disabled} type="button">
      <div className="flex flex-row justify-start items-center gap-3">
        <>
          {icon ? (
            <div className="quiz-answer-item__icon">
              {icon.length > 10 ?
                <img src={pathToIcon} alt="QUIZ_ANSWER_ICON" /> :
                icon}
            </div>
          ) : null}
        </>
        <>
          {text ? (
            <div className="quiz-answer-item__text">
              <TextView text={text} styles={`quiz-answer-item__text ${textStyles}`} />
            </div>
          ) : null}
        </>
      </div>
      {multiselect && (
      <>
        <img src={getState.isActive ? ICONS_MAIN.checkboxActiveIcon : ICONS_MAIN.checkboxDisabledIcon} alt="CHECKBOX_ACTIVE" />
      </>
      )}
    </button>
  );
};

export { QuizButtonView };

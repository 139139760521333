import React, { RefObject } from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { VerificationCodeFormView } from '@components/layout/VerificationCodeForm';
import { motion } from 'framer-motion';
import { statePopupViewForward } from '@components/texts/StatePopup';

export type verificationCodeScreenPresenterProps = {
    currentPhoneNumber: string;
    digits: {[key: number]: string}
    isButtonDisabled: boolean;
    onDigitsChange: (index: number, value: string) => void
    onContinueButtonPress: () => Promise<void>;
    errorPopupRef: RefObject<statePopupViewForward>;
    timer: number;
    handleResend: () => Promise<void>;
};

const VerificationCodeScreenPresenter: React.FC<verificationCodeScreenPresenterProps> = ({ onContinueButtonPress, onDigitsChange, currentPhoneNumber, isButtonDisabled, errorPopupRef, timer, handleResend }) => {
  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton drawerButton personalAccountButton={false} />
      </div>
      <PrimaryLayoutView>
        <VerificationCodeFormView
          handleResend={handleResend}
          timer={timer}
          errorPopupRef={errorPopupRef}
          onContinueButtonPress={onContinueButtonPress}
          onChange={onDigitsChange}
          isButtonDisabled={isButtonDisabled}
          currentPhoneNumber={currentPhoneNumber}
        />
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { VerificationCodeScreenPresenter };

import React, { useCallback, useContext, useMemo } from 'react';
import { HomeScreenPresenter, homeScreenPresenterProps } from '@screens/HomeScreen/view';
import { ICONS_MAIN } from '@utils/constants/icons';
import { useNavigate } from 'react-router-dom';
import { quiz } from '@typo/pub';
import { StepContext } from '@core/context/stepContext';
import { __nec_app__ } from '@core/ApplicationLogic';
import { gTagEventIds } from '@utils/constants/strings';

type homeScreenContainerProps = {};

const HomeScreenContainer: React.FC<homeScreenContainerProps> = ({}) => {
  const navigator = useNavigate();
  const entryQuiz = useMemo(() => quiz?.quizzes[quiz.entryPoint]?.data?.slug || '', []);
  const { setStep } = useContext(StepContext);
  const onStartButtonPress = useCallback(async () => {
    await __nec_app__.analytics.newConversionEvent(gTagEventIds.homeScreenId);
    navigator(`/${entryQuiz}`);
    setStep(0);
  }, [entryQuiz, navigator]);

  const cardProperties = {
    buttonText: 'Start',
    icon: ICONS_MAIN.nectionParrotIcon,
    onButtonPress: onStartButtonPress,
    header: 'Become a person who have the most meaningful relationships',
    subHeader: 'Take a quiz and get your own personal\n' +
        'networking plan',
  };

  const ViewProps: homeScreenPresenterProps = {
    cardProperties,
  };

  return <HomeScreenPresenter {...ViewProps} />;
};

export { HomeScreenContainer };

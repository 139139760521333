import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { QuizScreenContainer } from '@screens/QuizScreen';
import { AuthScreenContainer } from '@screens/AuthScreen';
import { PersonalAccountScreenContainer } from '@screens/PersonalAccountScreen';
import { SubscriptionScreenContainer } from '@screens/SubscriptionScreen';
import { VerificationCodeScreenContainer } from '@screens/VerificationCodeScreen';
import { HomeScreenContainer } from '@screens/HomeScreen';
import { quiz } from '@typo/pub';
import { NetworkingPlanScreenContainer } from '@screens/NetworkingPlanScreen';
import { AnalyzingPlanScreenContainer } from '@screens/AnalyzingPlanScreen';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AfterPaymentScreenContainer } from '@screens/AfterPaymentScreen';
import { AfterFailedPaymentScreenContainer } from '@screens/AfterFailedPaymentScreen';

type mainNavigationViewProps = {};

const MainNavigationPresenter: React.FC<mainNavigationViewProps> = ({}) => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/" element={<HomeScreenContainer />} />
          {
            Object.values(quiz.quizzes).map((quiz) => {
              return <Route key={quiz.data.slug} path={`/${quiz.data.slug}/*`} element={<QuizScreenContainer quizData={quiz} />} />;
            })
          }
          <Route path="/analyzing" element={<AnalyzingPlanScreenContainer />} />
          <Route path="/networking" element={<NetworkingPlanScreenContainer />} />
          <Route path="/auth" element={<AuthScreenContainer />} />
          <Route path="/verify" element={<VerificationCodeScreenContainer />} />
          <Route path="/personal-account" element={<PersonalAccountScreenContainer />} />
          <Route path="/subscriptions" element={<SubscriptionScreenContainer />} />
          <Route path="/after" element={<AfterPaymentScreenContainer />} />
          <Route path="/after-failed" element={<AfterFailedPaymentScreenContainer />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export { MainNavigationPresenter };
export type { mainNavigationViewProps };

import React from 'react';
import { AnalyzingPlanScreenPresenter, analyzingPlanScreenPresenterProps } from '@screens/AnalyzingPlanScreen/view';

type analyzingPlanScreenContainerProps = {};

const AnalyzingPlanScreenContainer: React.FC<analyzingPlanScreenContainerProps> = () => {
  const ViewProps: analyzingPlanScreenPresenterProps = {

  };

  return <AnalyzingPlanScreenPresenter {...ViewProps} />;
};

export { AnalyzingPlanScreenContainer };

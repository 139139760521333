import React from 'react';
import './index.css';

type labelRadioButtonViewProps = {
    text: string;
    value: string;
    name: string;
    id: string;
    onChange: (value: string) => void;
};

const LabelRadioButtonView: React.FC<labelRadioButtonViewProps> = ({ text, onChange, value, name, id }) => {
  return (
    <div className="cancel_reason_id">
      <input onChange={(event) => onChange(event.target.value)} id={id} value={value} type="radio" name={name} />
      <label className="cancel_modal_radio_label" htmlFor={id}>{text}</label>
    </div>
  );
};

export { LabelRadioButtonView };

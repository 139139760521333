import React, { useEffect, useRef, useState } from 'react';
import { MainNavigationContainer } from '@core/navigation/MainNavigation';
import { ContextProvider } from '@core/context/stepContext';

type appMainProps = {};

function App(props: appMainProps) {
  return (
    <div className="w-full h-full">
      <div className="w-full h-full">
        <ContextProvider>
          <MainNavigationContainer />
        </ContextProvider>
      </div>
    </div>
  );
}

export default App;

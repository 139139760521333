import axios from 'axios';
import { getEnv } from '@core/env';
import { __nec_app__ } from '@core/ApplicationLogic';

export const API_PATHS = {
  REFRESH_TOKEN: '/refreshToken',
  SUBSCRIPTION_INFO: '/subscription-info',
  VERIFY_CODE: '/verify',
  SUBSCRIPTION_LIST: '/get-subscription-list',
  CANCEL_SUBSCRIPTION: '/cancel-subscription-paypro',
  CHECK_EMAIL: '/check-email-exists',
  LOGIN: '/login',
};

const getAPIUrl = (): string => {
  switch (getEnv('REACT_APP_ENVIRONMENT')) {
    case 'development':
      return getEnv('REACT_APP_DEV_API_URL');
    case 'production':
      return getEnv('REACT_APP_PROD_API_URL');
    case 'local':
      return getEnv('REACT_APP_TEST_API');
    default:
      return getEnv('REACT_APP_TEST_API');
  }
};
const axiosImpl = axios.create({
  baseURL: `${getAPIUrl()}${getEnv('REACT_APP_API_PATH_V2')}`,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosImpl.interceptors.request.use(async (config) => {
  const token = __nec_app__.currentUser.tokens.accessToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosImpl.interceptors.response.use(async (config) => ({
  ...config,
  success: config.status === 200 || config.status === 201,
}),
async (error) => Promise.reject(error));

export { axiosImpl };

import { __nec_app__ } from '@core/ApplicationLogic';
import { getEnv } from '@core/env';

class Analytics {
  private readonly isDev: boolean;

  constructor() {
    this.isDev = getEnv('REACT_APP_ENVIRONMENT') === 'development';
  }

  public async newEvent(key: string, withUserId: boolean = false, data: { [key: string]: string } = {}) {
    const userId = __nec_app__.currentUser.generatePseudoId();
    if (!window.gtag) {
      console.warn('Analytics and Ads script is not exists!');
      return;
    }
    window.gtag('event', 'screen_view', {
      app_name: 'Nection Sale',
      screen_name: key,
      ...(withUserId && { userSessionIdentifier: userId }),
      ...(data !== void 0 && { data }),
      ...(this.isDev && { page_title: 'development' }),
    });
  }

  public async newConversionEvent(sendTo: string, callback: Function = () => {}, additionalStores: {[key: string]: string | number | boolean} = {}) {
    const userId = __nec_app__.currentUser.generatePseudoId();
    if (!window.gtag) {
      console.warn('Analytics and Ads script is not exists!');
      return;
    }
    window.gtag('event', 'conversion', {
      send_to: `AW-11039744306/${sendTo}`,
      event_callback: callback,
      ...additionalStores,
    });
  }
}

export { Analytics };

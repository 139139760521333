import React, { useEffect, useState } from 'react';
import './index.css';

type verificationCodeViewProps = {
    onChange: (index: number, value: string) => void;
};
type verificationCodeViewState = {
  currentValue: string;
};

const VerificationCodeView: React.FC<verificationCodeViewProps> = ({ onChange }) => {
  const [getState, setState] = useState<verificationCodeViewState>({
    currentValue: '',
  });
  useEffect(() => {
    const maxIndex = 5;
    const verificationCodeItems = document.querySelectorAll<HTMLInputElement>('.code_input_item');
    verificationCodeItems.forEach((input, index) => {
      input.oninput = function onInput() {
        if (input.value.length > 1) {
          input.value = input.value[0];
        }
      };
      input.addEventListener('keyup', ({ key, currentTarget }) => {
        const isLastIndex = index >= maxIndex;
        const isFirstIndex = index <= 0;
        if (key === 'Backspace' || key === 'Delete') {
          // @ts-ignore
          currentTarget!.value = '';
          onChange && onChange(index, '');
          setTimeout(() => {
            if (!isFirstIndex) {
              verificationCodeItems[index - 1].focus();
            }
          }, 0);
        } else {
          // @ts-ignore
          const inputValue = currentTarget!.value;
          onChange && onChange(index, inputValue);
          if (!isLastIndex && inputValue.length > 0) {
            verificationCodeItems[index + 1].focus();
            if (verificationCodeItems[index + 1].value !== '') {
              verificationCodeItems[index + 1].value = '';
            }
          }
        }
      });
    });
  }, []);

  return (
    <div className="code_input_box">
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        minLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
      <input
        type="number"
        className="code_input_item w-input"
        maxLength={1}
        name="field"
        data-name="Field"
        placeholder=""
        id="item2"
        required
      />
    </div>
  );
};

export { VerificationCodeView };

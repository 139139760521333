import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VerificationCodeScreenPresenter, verificationCodeScreenPresenterProps } from '@screens/VerificationCodeScreen/view';
import { useLocation, useNavigate } from 'react-router-dom';
import { Request } from '@api/Request';
import { __nec_app__ } from '@core/ApplicationLogic';
import { statePopupViewForward } from '@components/texts/StatePopup';
import { initialNum } from '@utils/constants/strings';

type verificationCodeScreenContainerProps = {};
type verificationCodeScreenContainerState = {
  digits: {[key: number]: string};
  isButtonDisabled: boolean;
  isError: boolean;
};

const VerificationCodeScreenContainer: React.FC<verificationCodeScreenContainerProps> = ({}) => {
  const [currentPhone, setCurrentPhone] = useState<string>('');
  const [timer, setTimer] = useState(initialNum);
  const [getState, setState] = useState<verificationCodeScreenContainerState>({
    digits: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
    },
    isButtonDisabled: false,
    isError: false,
  });
  const { state } = useLocation();
  const navigator = useNavigate();
  const errorPopupRef = useRef<statePopupViewForward>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleResend = useCallback(async () => {
    if (!currentPhone) return;
    await Request.signIn(currentPhone);
    setTimer(initialNum);
  }, [currentPhone]);

  const onContinueButtonPress = useCallback(async () => {
    try {
      const isValid = Object.values(getState.digits).includes('');
      if (isValid || !currentPhone) {
        return;
      }
      const code = Object.values(getState.digits).join('');
      const response = await Request.verify(code, currentPhone);
      if (response?.statusCode === 200 && response?.dataset) {
        const isSaved = __nec_app__.currentUser.saveIntoUser(response?.dataset?.user);
        if (isSaved) {
          navigator('/personal-account', { replace: true });
        }
        // Handle Exception
      } else if (errorPopupRef && errorPopupRef.current) {
        errorPopupRef.current.show('Wrong confirmation code');
      }
    } catch (e) {
      console.warn('VerifyCode.onContinueButtonPress.ex', e);
    }
  }, [errorPopupRef, currentPhone, getState.digits, navigator]);

  const onDigitsChange = useCallback((index: number, value: string) => {
    const updateDigits = getState.digits;
    updateDigits[index] = value;
    setState((prev) => ({ ...prev, digits: updateDigits }));
  }, [getState, timer, onContinueButtonPress]);

  const tickTimer = useCallback(async () => {
    if (timer > 0) {
      timeout.current = setTimeout(() => { setTimer((prev) => prev - 1); }, 1000);
    }
  }, [timer, onContinueButtonPress]);

  useEffect(() => {
    tickTimer().then();
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [timer, tickTimer]);

  useEffect(() => {
    if (typeof state?.phoneNumber === 'string') {
      setCurrentPhone(state?.phoneNumber);
    }
    if (typeof state?.initialTime === 'number') {
      setTimer(+state.initialTime);
    }
  }, [currentPhone, state]);

  useEffect(() => {
    const isValid = Object.values(getState.digits).includes('');
    if (getState.isButtonDisabled === isValid) {
      return;
    }
    setState((prev) => ({ ...prev, isButtonDisabled: isValid }));
  }, [getState, getState.digits]);
  // @ts-ignore
  const ViewProps: verificationCodeScreenPresenterProps = {
    isButtonDisabled: getState.isButtonDisabled,
    currentPhoneNumber: currentPhone,
    digits: getState.digits,
    onDigitsChange,
    onContinueButtonPress,
    errorPopupRef,
    timer,
    handleResend,
  };

  return <VerificationCodeScreenPresenter {...ViewProps} />;
};

export { VerificationCodeScreenContainer };

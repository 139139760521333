import React, { useContext } from 'react';
import { StepContext } from '@core/context/stepContext';

type quizProgressBarViewProps = {
    max: number;
}

const QuizProgressBarView: React.FC<quizProgressBarViewProps> = ({ max }) => {
  const { step } = useContext(StepContext);
  const percent = (step / max) * 100;
  return (
    <div className="w-full h-1 bg-progress-b rounded-md mb-1.5">
      <div style={{ maxWidth: `${percent}%` }} className="w-[100%] bg-primary-n z-40 h-1 rounded-md progress_animation" />
    </div>
  );
};

export { QuizProgressBarView };

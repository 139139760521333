import React, { useCallback, useState } from 'react';
import { arrayCodes } from '@components/inputs/PhoneNumber/utils';
import './index.css';

type phoneNumberInputViewProps = {
  codeNumber: string;
  phoneNumber: string;
  onChangeNumberInput: (value: string) => void;
  onCodeChange: (value: string) => void;
};
type phoneNumberInputViewState = {
  isFocused: boolean;
  filteredNumbers: Array<string[]>;
  isFilter: boolean;
};

const PhoneNumberInputView: React.FC<phoneNumberInputViewProps> = ({ phoneNumber, codeNumber, onCodeChange, onChangeNumberInput }) => {
  const [getState, setState] = useState<phoneNumberInputViewState>({
    isFocused: false,
    filteredNumbers: [],
    isFilter: false,
  });

  const onChangeCodeInput = useCallback((value: string) => {
    onCodeChange && onCodeChange(value);
    if (value.trim().length <= 0) {
      setState({ ...getState, isFilter: false, filteredNumbers: [] });
      return;
    }
    const filteredNumbers = arrayCodes.filter((codeStr) => {
      return `${codeStr[0]} ${codeStr[1]}`.includes(value.trim());
    });
    setState({ ...getState, isFilter: true, filteredNumbers });
  }, [getState, onCodeChange]);

  const onFocusOrBlur = useCallback((flag: boolean) => {
    if (flag) {
      setState({ ...getState, isFocused: true });
    } else {
      setTimeout(() => {
        setState({ ...getState, isFocused: false });
      }, 100);
    }
  }, [getState]);

  return (
    <div className="max-w-mob">
      <div className="text-input-container">
        <input value={codeNumber} onInput={(event) => onChangeCodeInput(event.currentTarget.value)} onFocus={() => onFocusOrBlur(true)} onBlur={() => onFocusOrBlur(false)} placeholder="+000" className={`text-input-container__codes ${getState.isFocused && 'text-input-container__number__active'}`} type="text" />
        <input value={phoneNumber} onInput={(event) => onChangeNumberInput(event.currentTarget.value)} placeholder="Enter phone number" className="text-input-container__number" type="number" />
      </div>
      <div className="t-dropdown-block">
        <ul className={`list t-dropdown-list ${getState.isFocused ? 'inline-block' : 'hidden'}`}>
          {(getState.isFilter ? getState.filteredNumbers : arrayCodes).map((el) => {
            return (
              <li onClick={() => { onChangeCodeInput(el[1]); }} key={el[0]} value={el[1]} className="t-dropdown-item">
                {el[0]}
                {' '}
                {el[1]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { PhoneNumberInputView };

import React, { useState, useEffect } from 'react';
import './index.css';
import {LOCAL_KEYS} from "@core/CurrentUser";

const CountdownTimer: React.FC = () => {
  const initialTime = 600; // 10 minutes in seconds
  const [time, setTime] = useState<number>(() => {
    const storedTime = localStorage.getItem(LOCAL_KEYS.COUNTDOWN_TIMER);
    return storedTime ? Number(storedTime) : initialTime;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      localStorage.setItem(LOCAL_KEYS.COUNTDOWN_TIMER, String(initialTime));
    } else {
      localStorage.setItem(LOCAL_KEYS.COUNTDOWN_TIMER, String(time));
    }
  }, [time]);

  const formatTime = (remainingTime: number): string => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
      <div className='countdown-timer'>Reserved price for: {formatTime(time)}</div>
  );
};

export default CountdownTimer;

import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { motion } from 'framer-motion';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { NoticeAlertView } from '@components/texts/NoticeAlert';
import { StepTextView } from '@components/texts/StepText';
import './styles.css';

export type afterPaymentScreenPresenterProps = {
  goToAppStore: () => void;
  goToGooglePlay: () => void;
  email: string;
};

const AfterPaymentScreenPresenter: React.FC<afterPaymentScreenPresenterProps> = ({ email, goToGooglePlay, goToAppStore }) => {
  return (
    <motion.div
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton={false} drawerButton personalAccountButton />
      </div>
      <PrimaryLayoutView>
        <>
          <div className="card-container">
            <div className="w-full mb-3">
              <NoticeAlertView
                bgColor="bg-successful-b"
                icon={ICONS_MAIN.successFullCheckIcon}
                text="Payment successful"
                textStyles="primary_subHeader"
              />
            </div>
            <div>
              <img className="w-full h-100 floating_animation" src={ICONS_MAIN.nectionParrotCoolIcon} alt="CARD_ICON" />
            </div>
            <>
              <TextView text="Welcome to Nection!" styles="card-header" />
            </>
            <>
              <TextView text="We're so happy to have you!" styles="card-subHeader" />
              <TextView text="Now you have full access to grown your personal network" styles="card-subHeader" />
            </>
          </div>
          <div className="content-card-container">
            <TextView text="To active your account:" styles="card-header" />
            <div>
              <StepTextView step={1} text="Download App" />
            </div>
            <div className="w-full text-center">
              <TextView
                text="Tap the buttons below to download Nection for iOS or Android."
                styles="primary_subHeader"
              />
            </div>
            <div className="w-full">
              <PrimaryButtonView leftIcon={ICONS_MAIN.appStoreFlatIcon} textStyles="platform-button__text" styles="ios-button__container mb-4" onPress={goToAppStore} text="Download for iOS" />
              <PrimaryButtonView leftIcon={ICONS_MAIN.playMarketFlatIcon} textStyles="platform-button__text" styles="android-button__container" onPress={goToGooglePlay} text="Download for Android" />
            </div>
            <div className="and__container mb-6">
              <div className="and__separator" />
              <TextView text="AND" styles="and__text" />
              <div className="and__separator" />
            </div>
            <div>
              <StepTextView step={2} text="Register" />
            </div>
            <div className="text-justify px-5 mb-6">
              <span className="primary_subHeader">
                Use your first and last names with email
                {' '}
                <strong className="text-primary-n">
                  {email || 'youremail@email.com'}
                </strong>
                {' '}
                when you start register in Nection to take access.
              </span>
            </div>
            <div>
              <img src={ICONS_MAIN.phoneMockupIcon} alt="PHONE_MOCKUP_ICON" />
            </div>
          </div>
        </>
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { AfterPaymentScreenPresenter };

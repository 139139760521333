import React from 'react';
import { ICONS_MAIN } from '@utils/constants/icons';

const PreloaderView: React.FC<{}> = () => {
  return (
    <div className="w-full h-full flex flex-row justify-center items-center">
      <img src={ICONS_MAIN.preloaderIcon} alt="PRELOADER_VIEW" />
    </div>
  );
};

export { PreloaderView };

import React, { useEffect } from 'react';
import { AfterPaymentScreenPresenter, afterPaymentScreenPresenterProps } from '@screens/AfterPaymentScreen/view';
import { getEnv } from '@core/env';
import { __nec_app__ } from '@core/ApplicationLogic';
import { useLocation } from 'react-router-dom';

type afterPaymentScreenContainerProps = {};

const AfterPaymentScreenContainer: React.FC<afterPaymentScreenContainerProps> = ({}) => {
  const location = useLocation();
  const goToAppStore = () => {
    window.location.href = getEnv('REACT_APP_ONE_LINK');
  };

  const { email } = __nec_app__.currentUser.user;

  const goToGooglePlay = () => {
    window.location.href = getEnv('REACT_APP_ONE_LINK');
  };

  const ViewProps: afterPaymentScreenPresenterProps = {
    goToAppStore,
    goToGooglePlay,
    email,
  };

  useEffect(() => {
    __nec_app__.analytics.newEvent('Subscription_Success', true).then();
  }, []);

  return <AfterPaymentScreenPresenter {...ViewProps} />;
};

export { AfterPaymentScreenContainer };

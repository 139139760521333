import React from 'react';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';
import './index.css';

type downloadsCardViewProps = {
    starsCount: string;
    downloads: string;
};

const DownloadsCardView: React.FC<downloadsCardViewProps> = ({ downloads, starsCount }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < Math.round(+starsCount); i++) {
      const key = `${i}${starsCount}`;
      stars.push(
        <img
          key={key}
          src={ICONS_MAIN.redStarIcon}
          loading="lazy"
          alt=""
          className="image-15"
        />,
      );
    }
    return stars;
  };

  return (
    <div className="card-container star-count-container">
      <div className="star-count-container__heading">
        <TextView text={`${starsCount}`} styles="" />
        <img src={ICONS_MAIN.appStoreViewIcon} alt="APPS_STORE_ICON" />
      </div>
      <div className="star-count-container__stars">
        {renderStars()}
      </div>
      <div className="star-count-container__downloads">
        {downloads}
        K downloads
      </div>
    </div>
  );
};

export { DownloadsCardView };

import { QuizFile } from '@type/Quiz';
import labelJSON from './label.json';
import mockJSON from './mocks.json';
import quizJSON from './quiz.json';

const fallbackQuiz = {
  entryPoint: 'unknown',
  quizzes: {},
};
// @ts-ignore
const quiz = quizJSON.quizzes ? quizJSON as unknown as QuizFile : fallbackQuiz as unknown as QuizFile;

const label = labelJSON;
const mock = mockJSON;

export { label, quiz, mock, fallbackQuiz };

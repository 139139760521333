import React, { useCallback } from 'react';
import { ICONS_MAIN } from '@utils/constants/icons';
import { TextView } from '@components/texts/TextView';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

const GetPlanMyCardView = ({}) => {
  const onButtonPress = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className="card-container">
      <div className="w-full flex flex-row justify-start">
        <img src={ICONS_MAIN.nectionFullLogoIcon} alt="LOGO_IMAGE" />
      </div>
      <div className="primary_header text-left my-6 text-2xl">
        <TextView text="Empower your networking with the best relationship insights" styles="" />
      </div>
      <div className="w-full">
        <PrimaryButtonView styles="primary-button" text="Get My Plan" textStyles="primary-button-text" onPress={onButtonPress} />
      </div>
    </div>
  );
};

export { GetPlanMyCardView };

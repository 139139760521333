import React, { RefObject } from 'react';
import { VerificationCodeView } from '@components/inputs/VerificationCode';
import { TextView } from '@components/texts/TextView';
import './index.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import StatePopupView, { statePopupViewForward } from '@components/texts/StatePopup';

type verificationCodeFormViewProps = {
  currentPhoneNumber: string;
  isButtonDisabled: boolean;
  onChange: (index: number, value: string) => void;
  onContinueButtonPress: () => Promise<void>;
  errorPopupRef: RefObject<statePopupViewForward>;
  timer: number;
  handleResend: () => Promise<void>;
};

const VerificationCodeFormView: React.FC<verificationCodeFormViewProps> = ({ onContinueButtonPress, onChange, isButtonDisabled, currentPhoneNumber, errorPopupRef, timer, handleResend }) => {
  return (
    <div className="centered-container">
      <div
        id="email-form"
        className="verification-code-form"
      >
        <div className="primary_header mb-2 mt-6">
          <TextView text="Verification Code" styles="" />
        </div>
        <div className="primary_subHeader mb-6">
          <TextView
            text={`We have sent a verification code to your
        phone
        (${currentPhoneNumber || '+000000000'}), enter it below`}
            styles=""
          />
        </div>
        <div>
          <VerificationCodeView onChange={onChange} />
        </div>
        <StatePopupView
          ref={errorPopupRef}
          fallBackText="Wrong confirmation code"
        />
        <div className="w-full mt-3">
          <PrimaryButtonView
            disabled={isButtonDisabled}
            styles={`primary-button ${isButtonDisabled && 'primary-button__disabled'}`}
            text="Continue"
            textStyles="primary-button-text"
            onPress={onContinueButtonPress}
          />
          <div className="w-full flex flex-row justify-center mt-6">
            <PrimaryButtonView
              styles="resend-button"
              textStyles={`resend-button__text ${timer && 'resend-button__disabled'} text-sm font-bold`}
              text={timer ? `Resend code in ${timer}` : 'Resend code'}
              onPress={handleResend}
              disabled={!!timer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerificationCodeFormView };

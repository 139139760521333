import React from 'react';
import ReactDOM from 'react-dom/client';
import '@styles/default/index.css';
import '@styles/default/normalize.css';
import '@styles/elements/animations.css';
import { __nec_app__ } from '@core/ApplicationLogic';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

window.__nec_app__ = __nec_app__;

const onStart = () => {
  try {
    __nec_app__.onStartApplication();
  } catch (ex) {
    console.warn(ex);
  }
};

onStart();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
);

export interface IEnvironment {
    REACT_APP_PROD_API_URL: string;
    REACT_APP_DEV_API_URL: string;
    REACT_APP_TEST_API: string;
    REACT_APP_API_PATH_V2: string;
    REACT_APP_APP_STORE_URL: string;
    REACT_APP_PLAY_MARKET_URL: string;
    REACT_APP_PAY_PRO_CHECKOUT: string;
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_ONE_LINK: string;
}

const getEnv = (key: keyof IEnvironment): string => {
  if (process.env[key] === void 0 || process.env[key] === null) {
    return '';
  }
  return <string>process.env[key];
};

export { getEnv };

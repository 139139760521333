import React from 'react';
import { ICONS_MAIN } from '@utils/constants/icons';
import { BackButtonView } from '@components/buttons/BackButton';
import { IconButtonView } from '@components/buttons/IconButton';
import { MainDrawerController } from '@core/navigation/drawer/MainDrawer';

export type mainHeaderViewProps = {
    leftButton: boolean;
    rightButton: boolean;
    drawerButton: boolean;
    personalAccountButton: boolean;
    onPersonalAccountPress: () => void;
    onBurgerButtonPress: (flag: boolean) => void;
    isActiveDrawer: boolean;
};

const MainHeaderView: React.FC<mainHeaderViewProps> = ({ leftButton, drawerButton, personalAccountButton, onPersonalAccountPress, onBurgerButtonPress, isActiveDrawer }) => (
  <>
    <MainDrawerController isActive={isActiveDrawer} onClose={() => onBurgerButtonPress(false)} />
    <header className="h-11 px-[2%] pt-[10px] pb-[5px]">
      <div className="h-full flex flex-row justify-start items-center">
        <div className="w-[5%] flex flex-row justify-start items-center">
          {leftButton ? <BackButtonView showArrow /> : null}
        </div>
        <div className="w-[90%]">
          <div className="w-full h-full flex flex-row justify-center items-center">
            <img className="w-[102px]" src={ICONS_MAIN.nectionFullLogoIcon} alt="LOGO" />
          </div>
        </div>
        <div className="w-[5%] h-full flex flex-row justify-end items-center">
          <div className="mr-[23%]">
            {personalAccountButton ? <IconButtonView onPress={onPersonalAccountPress} icon={ICONS_MAIN.userProfileIcon} disabled={false} /> : null}
          </div>
          <div className="">
            {drawerButton ? <IconButtonView onPress={() => onBurgerButtonPress(true)} icon={ICONS_MAIN.burgerButtonIcon} disabled={false} /> : null}
          </div>
        </div>
      </div>
    </header>
  </>
);

export { MainHeaderView };

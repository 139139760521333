import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import './index.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

type statePopupViewProps = {
    fallBackText: string;
    isAlwaysOpened?: boolean;
    showButton?: boolean;
    buttonText?: string;
    onButtonPress?: () => void;
};

export type statePopupViewForward = {
    show: (text: string) => void;
    close: () => void;
}

type statePopupViewState = {
    isVisible: boolean;
    text: string;
};

const mountedStyle = {
  animation: 'inAnimation 250ms ease-in',
};
const unmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};

const StatePopupView: React.ForwardRefRenderFunction<statePopupViewForward, statePopupViewProps> = ({ isAlwaysOpened, fallBackText, buttonText, showButton, onButtonPress }, ref) => {
  const [getState, setState] = useState<statePopupViewState>({
    isVisible: isAlwaysOpened || false,
    text: fallBackText,
  });

  const close = () => {
    setState({ ...getState, isVisible: false });
  };

  const show = (value: string) => {
    setState({ ...getState, isVisible: true, text: value });
    console.log('zxcxzczxc');
    setTimeout(() => {
      setState({ ...getState, isVisible: false, text: value });
    }, 5000);
  };

  const handleButtonPress = useCallback(() => {
    onButtonPress && onButtonPress();
  }, []);

  useImperativeHandle(ref, () => ({
    close,
    show,
  }));

  if (!getState.isVisible) {
    return null;
  }
  return (
    <div className="state-popup-container">
      <div className="state-popup-container__text">
        <TextView styles="" text={getState.text} />
      </div>
      <div>
        {showButton &&
        <PrimaryButtonView styles="tertiary-button pl-0.5" text={buttonText || ''} textStyles="tertiary-button__text text-base font-bold" type="button" onPress={handleButtonPress} />}
      </div>
    </div>
  );
};

export default forwardRef(StatePopupView);

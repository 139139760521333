import React from 'react';
import { TextView } from '@components/texts/TextView';

type noticeAlertViewProps = {
    icon: string;
    text: string;
    textStyles?: string;
    styles?: string;
    iconStyles?: string;
    bgColor: string;
};

const NoticeAlertView: React.FC<noticeAlertViewProps> = ({ bgColor, iconStyles, styles, textStyles, icon, text }) => {
  return (
    <div className={`flex flex-1 justify-start items-center py-3.5 px-4 gap-3 ${bgColor || ''} rounded-primary ${styles || ''}`}>
      <div>
        <img className={`w-8 ${iconStyles || ''}`} src={icon} alt="NOTICE_ICON" />
      </div>
      <div className={`normal-text ${textStyles || ''}`}>
        <TextView text={text} styles="" />
      </div>
    </div>
  );
};

export { NoticeAlertView };

import React from 'react';
import texts from '@src/typo/label.json';
/**
 * Prepared for localization
 */

type textViewProps = {
    text: string;
    styles: string;
    key?: string;
    variables?: Array<string>;
};

const TextView: React.FC<textViewProps> = ({ text, styles }) => (
  <span className={styles}>
    {text}
  </span>
);

export { TextView };

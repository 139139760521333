import React, { useCallback, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import './index.css';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

type renewSubscriptionModalProps = {
    onRenewSubscription: () => Promise<void>;
};
type renewSubscriptionModalState = {
    isOpened: boolean;
};

type renewSubscriptionForward = {
    open: () => void;
    close: () => void;
}

const RenewSubscriptionModal: React.ForwardRefRenderFunction<renewSubscriptionForward, renewSubscriptionModalProps> = ({ onRenewSubscription }, ref) => {
  const [getState, setState] = useState<renewSubscriptionModalState>({
    isOpened: false,
  });

  const open = () => {
    setState({ ...getState, isOpened: true });
  };

  const close = () => {
    setState({ ...getState, isOpened: false });
  };

  const onButtonPress = useCallback(() => {
    onRenewSubscription && onRenewSubscription();
    setTimeout(() => {
      close();
    }, 1000);
  }, []);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal
      closeTimeoutMS={500}
      aria={{ modal: false }}
      ariaHideApp={false}
      htmlOpenClassName=""
      onRequestClose={close}
      overlayClassName="modal-bg"
      isOpen={getState.isOpened}
      className="modal-content cancel_subscription_modal_content"
    >
      <div>
        <div className="flex flex-row justify-between items-center mb-3">
          <TextView styles="primary_subHeader" text="You sure, you want to renew your current subscription?" />
          <div className="cursor-pointer" onClick={close}>
            <img src={ICONS_MAIN.closeIcon} alt="CLOSE_BUTTON_ICON" />
          </div>
        </div>
        <div className="mt-10 w-full">
          <PrimaryButtonView styles="primary-button max-w-[100%]" text="Yes! Renew my subscription" textStyles="primary-button-text" onPress={onButtonPress} />
        </div>
      </div>
    </Modal>
  );
};

export default React.forwardRef(RenewSubscriptionModal);

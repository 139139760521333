import React from 'react';
import { Card, QuizData, QuizQuestion } from '@type/Quiz';
import { QuizQuestionView } from '@components/layout/QuizQuestion';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { CardView } from '@components/layout/Card';
import { motion } from 'framer-motion';

export type questionScreenPresenterProps = {
  question?: QuizQuestion | Card;
  quizData?: QuizData;
  goNext: (value: string) => void;
  onSelectAnswer: (value: string) => Promise<void>;
  selectedAnswers: Array<string>;
};

const QuestionScreenPresenter: React.FC<questionScreenPresenterProps> = ({ selectedAnswers, onSelectAnswer, goNext, question, quizData }) => {
  const renderQuestionByType = () => {
    switch (question?.type) {
      case 'question':
        return <QuizQuestionView selectedAnswers={selectedAnswers} onSelectAnswer={onSelectAnswer} goNext={goNext} {...question as QuizQuestion} />;
      case 'card':
        return <CardView onButtonPress={() => goNext((question as Card).value)} {...question as unknown as Card} />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[90vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PrimaryLayoutView>
        <>
          {renderQuestionByType()}
        </>
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { QuestionScreenPresenter };

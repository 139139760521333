import React, { useCallback, useState } from 'react';
import { QuestionScreenPresenter, questionScreenPresenterProps } from '@screens/QuestionScreen/view';
import { Card, QuizData, QuizQuestion } from '@type/Quiz';
import { useNavigate } from 'react-router-dom';
import { __nec_app__ } from '@core/ApplicationLogic';

type questionScreenContainerProps = {
  question?: QuizQuestion | Card;
  quizData?: QuizData;
  onQuestionPress: (step: number) => void;
  currentIndex: number;
  nextSlug: string | null;
};

type questionScreenContainerState = {
  selectedAnswers: Array<string>;
};

const QuestionScreenContainer: React.FC<questionScreenContainerProps> = ({ currentIndex, nextSlug, onQuestionPress, question, quizData }) => {
  const [getState, setState] = useState<questionScreenContainerState>({
    selectedAnswers: [],
  });
  const navigator = useNavigate();

  const goNext = useCallback((value: string) => {
    if (!value || (getState.selectedAnswers.length <= 0 && (question as QuizQuestion).multiselect)) {
      console.warn('goNext.ex', value, 'is not a string!, or answers is not selected');
      return;
    }
    switch (question?.type) {
      case 'question':
        localStorage.setItem(`${question.name}`, (question as QuizQuestion).multiselect ? getState.selectedAnswers.join(';') : value);
        break;
      case 'card':
        localStorage.setItem(`${question.name}`, value);
        break;
      default:
        console.log('this type not implemented!');
    }
    if (question?.gtagId) {
      __nec_app__.analytics.newConversionEvent(question.gtagId);
    }
    const nextSlugNull = nextSlug ? `/${quizData?.slug}/${nextSlug}` : '/analyzing';
    onQuestionPress && onQuestionPress(currentIndex);
    navigator(nextSlugNull);
  }, [getState.selectedAnswers, navigator, nextSlug, question, quizData?.slug, onQuestionPress, currentIndex]);

  const onSelectAnswer = useCallback(async (value: string) => {
    if ((question as QuizQuestion).multiselect) {
      const element = getState.selectedAnswers.find((answer) => {
        return answer === value;
      });
      if (element) {
        setState({ ...getState, selectedAnswers: getState.selectedAnswers.filter((answer) => element !== answer) });
      } else {
        setState({ ...getState, selectedAnswers: [...getState.selectedAnswers, value] });
      }
    } else {
      goNext(value);
    }
  }, [getState, goNext, question]);

  const ViewProps: questionScreenPresenterProps = {
    selectedAnswers: getState.selectedAnswers,
    quizData,
    question,
    onSelectAnswer,
    goNext,
  };

  return <QuestionScreenPresenter {...ViewProps} />;
};

export { QuestionScreenContainer };

import React from 'react';
import './index.css';
import { TextView } from '@components/texts/TextView';

type stepTextViewProps = {
    step: number;
    text: string;
}
const StepTextView: React.FC<stepTextViewProps> = ({ step, text }) => {
  return (
    <div className="step-container">
      <span className="step-container__num">
        <TextView text={`${step}`} styles="" />
      </span>
      <span className="step-container__text">
        <TextView text={text} styles="" />
      </span>
    </div>
  );
};

export { StepTextView };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import { TwoSideTextView } from '@components/texts/TwoSideText';
import './index.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { ImageButtonView } from '@components/buttons/ImageButton';
import { ICONS_MAIN } from '@utils/constants/icons';
import { __nec_app__ } from '@core/ApplicationLogic';
import { Request } from '@api/Request';
import { subscriptionProviders } from '@utils/constants/strings';
import { subscriptionState } from '@screens/PersonalAccountScreen';
import { PreloaderView } from '@components/loaders/PreloaderView';

type personalAccountViewProps = {
    onSubscriptionButtonPress: () => void;
    goToAppStore: () => void;
    goToGooglePlay: () => void;
    onLogoutButtonPress: () => void;
    subscriptionState: subscriptionState;
    onCancelModalOpen: () => void;
    onRenewalModalOpen: () => void;
    isLoading: boolean;
};

const PersonalAccountView: React.FC<personalAccountViewProps> = ({
  onLogoutButtonPress,
  goToAppStore,
  goToGooglePlay,
  onSubscriptionButtonPress,
  subscriptionState,
  onRenewalModalOpen,
  onCancelModalOpen,
  isLoading,
}) => {
  const { firstName, lastName, countryCode, phone, email } = useMemo(() => __nec_app__.currentUser.user, [__nec_app__]);
  const subscriptionProviderLabel = subscriptionState.providerName === subscriptionProviders.payPro ? 'Web' : 'Mobile Application';
  const subscriptionExpirationLabel = new Date(subscriptionState.expirationDate || 0).toISOString().split('T')[0];

  const subscriptionStatusLabel = useMemo(() => {
    let label = subscriptionState.currentlyActive ? 'Active' : 'Off';
    if (subscriptionState.providerName === 'PAY_PRO_GLOBAL' && subscriptionState.status !== 'Active') {
      label = subscriptionState.status;
    }
    return label;
  }, [subscriptionState]);

  const renderSubscriptionButton = useCallback(() => {
    if (!subscriptionState.currentlyActive) {
      return <PrimaryButtonView textStyles="primary-button-text" styles="primary-button w-full" onPress={onSubscriptionButtonPress} text="Subscribe" />;
    }
    if (subscriptionState.providerName === subscriptionProviders.payPro) {
      return subscriptionState.status === 'Suspended' ?
        <PrimaryButtonView textStyles="primary-button-text" styles="primary-button w-full" onPress={onRenewalModalOpen} text="Renew Subscription" />
        :
        <PrimaryButtonView textStyles="secondary-button-text" styles="secondary-button w-full" onPress={onCancelModalOpen} text="Unsubscribe" />;
    }
    return null;
  }, [
    onCancelModalOpen,
    onRenewalModalOpen,
    onSubscriptionButtonPress,
    subscriptionState.currentlyActive,
    subscriptionState.providerName,
    subscriptionState.status,
  ]);

  return (
    <div className="centered-container w-full">
      <div className="primary_header mb-4 mt-6">
        <TextView text="Personal account" styles="" />
      </div>
      <div className="personal-information-container w-full">
        {!isLoading ? (
          <>
            <TwoSideTextView leftText="Email" rightText={email} />
            {phone && <TwoSideTextView leftText="Phone" rightText={phone} />}
            {countryCode && <TwoSideTextView leftText="Country code" rightText={countryCode} />}
            {firstName && <TwoSideTextView leftText="Name" rightText={`${firstName} ${lastName}`} />}
            <div className="personal-information-container__separator" />
            <TwoSideTextView leftText="Subscription" rightText={subscriptionStatusLabel} />
            {subscriptionState.currentlyActive && (
            <>
              <TwoSideTextView leftText="Provider" rightText={subscriptionProviderLabel} />
              <TwoSideTextView leftText="Expires at" rightText={subscriptionExpirationLabel} />
            </>
            )}
          </>
        ) : <PreloaderView />}
      </div>
      <div className="w-full mt-6">
        {renderSubscriptionButton()}
      </div>
      <div className="mt-6">
        <PrimaryButtonView textStyles="tertiary-button__text text-lg" styles="tertiary-button w-full" onPress={onLogoutButtonPress} text="Log Out" />
      </div>
      <div className="w-full max-w-mob flex justify-around items-center mt-6">
        <ImageButtonView image={ICONS_MAIN.googlePlayIcon} onPress={goToGooglePlay} />
        <ImageButtonView image={ICONS_MAIN.appStoreIcon} onPress={goToAppStore} />
      </div>
    </div>
  );
};

export { PersonalAccountView };

import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { motion } from 'framer-motion';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';
import { NoticeAlertView } from '@components/texts/NoticeAlert';
import './styles.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

export type afterFailedPaymentScreenPresenterProps = {
  email: string;
  goToSubscriptionsList: () => void;
};

const AfterFailedPaymentScreenPresenter: React.FC<afterFailedPaymentScreenPresenterProps> = ({ email, goToSubscriptionsList }) => {
  return (
    <motion.div
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton={false} drawerButton personalAccountButton />
      </div>
      <PrimaryLayoutView>
        <>
          <div className="card-container">
            <div className="w-full mb-3">
              <NoticeAlertView
                bgColor="bg-warning-red"
                icon={ICONS_MAIN.xMarkIcon}
                text="Payment failed"
                textStyles="primary_subHeader"
              />
            </div>
            <div>
              <img className="w-full h-100 floating_animation" src={ICONS_MAIN.nectionFailedSubscription} alt="CARD_ICON" />
            </div>
            <>
              <TextView text="Oops!" styles="card-header" />
            </>
            <>
              <TextView text="Error Processing Payment" styles="card-subHeader" />
              <TextView text="Please, check your security code, card details and connection and try again" styles="card-subHeader" />
            </>
            <div className="mt-7 w-full">
              <PrimaryButtonView styles="primary-button" text="Try again" textStyles="primary-button-text" onPress={goToSubscriptionsList} />
            </div>
          </div>
        </>
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { AfterFailedPaymentScreenPresenter };

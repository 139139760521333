import React, { useEffect, useState } from 'react';
import { MainNavigationPresenter, mainNavigationViewProps } from '@core/navigation/MainNavigation/view';
import { __nec_app__ } from '@core/ApplicationLogic';

type mainNavigationProps = {};
type mainNavigationState = {};

const MainNavigationContainer: React.FC<mainNavigationProps> = ({}) => {
  const [getState, setState] = useState<mainNavigationState>({});

  const ViewProps: mainNavigationViewProps = {};

  useEffect(() => {
    __nec_app__.currentUser.generatePseudoId();
  }, []);

  return <MainNavigationPresenter {...ViewProps} />;
};

export { MainNavigationContainer };

import { requester } from '@api/requester';
import { API_PATHS } from '@api/axiosImpl';
import {
  CancelOrRenewSubscriptionRequest, CancelOrRenewSubscriptionResponse, CheckEmailRequest, CheckEmailResponse,
  GetRequest,
  LoginRequest,
  LoginResponse,
  SubscriptionInfoResponse,
  VerifyRequest,
  VerifyResponse,
} from '@type/Api';
import { SubscriptionListInfoResponse } from '@type/Models';
import { __nec_app__ } from '@core/ApplicationLogic';
import { initialNum } from '@utils/constants/strings';
import {LOCAL_KEYS} from "@core/CurrentUser";

export const ERROR_STATUS = 999;

export interface BaseResponse<T> {
  message?: string;
  statusCode: number | void;
  dataset: T;
}

export class Request {
  public static async getSubscriptionList() {
    try {
      const response = await requester<GetRequest, SubscriptionListInfoResponse>({
        url: API_PATHS.SUBSCRIPTION_LIST,
        retries: 1,
        withAccess: false,
        method: 'GET',
      });
      return {
        statusCode: response?.status,
        items: response?.data?.data?.response?.productPricings || [],
        currency: response?.data?.data?.response?.displayCurrency || '',
      };
    } catch (e) {
      console.warn('getSubscriptionList.ex', e);
      return {
        statusCode: ERROR_STATUS,
        items: [],
        currency: '',
      };
    }
  }

  public static signIn = async (phoneNumber: string) => {
    try {
      const deviceId = __nec_app__.currentUser.generatePseudoId();
      localStorage.setItem(LOCAL_KEYS.DEVICE_ID, deviceId);
      const requestBody = {
        phoneNumber,
        deviceId,
        isResend: false,
      };
      const response = await requester<LoginRequest, LoginResponse>({
        url: API_PATHS.LOGIN,
        data: requestBody,
        method: 'POST',
        withAccess: false,
        retries: 1,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const dataset = response?.data;
      return {
        statusCode: response?.status,
        statusMessage: dataset?.message || 'Something went wrong :(',
        phoneNumberExists: dataset?.phoneNumberExists || false,
        initialTime: dataset?.initialTime || initialNum,
      };
    } catch (e: any) {
      console.warn('signIn.ex', e);
      return {
        statusCode: ERROR_STATUS,
        statusMessage: e.toString(),
        phoneNumberExists: false,
      };
    }
  };

  public static verify = async (code: string, phoneNumber: string) => {
    try {
      const requestBody = {
        code,
        phoneNumber,
        deviceId: localStorage.getItem(LOCAL_KEYS.DEVICE_ID),
      };
      const response = await requester<VerifyRequest, VerifyResponse>({
        url: API_PATHS.VERIFY_CODE,
        retries: 1,
        withAccess: false,
        method: 'POST',
        data: requestBody,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        dataset: response?.data || null,
        statusCode: response?.status,
      };
    } catch (e) {
      console.warn('verifyCode.ex', e);
      return {
        dataset: null,
        statusCode: ERROR_STATUS,
      };
    }
  };

  public static getSubscriptionInfo = async (email: string, userId: string | null) => {
    const emptyResponseData = {
      subscriptionInfo: null,
      subscriptionProvider: null,
    };
    try {
      const requestBody = {
        email,
        userId: userId || null,
        checkOnlyWeb: !userId,
      };
      const response = await requester<GetRequest, SubscriptionInfoResponse>({
        url: API_PATHS.SUBSCRIPTION_INFO,
        retries: 1,
        withAccess: false,
        method: 'POST',
        data: requestBody,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        statusCode: response?.status,
        data: response?.data?.data || emptyResponseData,
      };
    } catch (e) {
      console.warn('getSubscriptionInfo.ex', e);
      return {
        data: emptyResponseData,
        statusCode: ERROR_STATUS,
      };
    }
  };

  public static cancelOrRenewSubscription = async (
    actionType: string,
    email: string,
    cancellationReasonId: number,
    reasonText: string,
  ) => {
    try {
      const requestBody = {
        actionType,
        email,
        cancellationReasonId: cancellationReasonId || 1,
        reasonText: reasonText || '',
      };
      const response = await requester<CancelOrRenewSubscriptionRequest, CancelOrRenewSubscriptionResponse>({
        url: API_PATHS.CANCEL_SUBSCRIPTION,
        retries: 1,
        withAccess: false,
        method: 'POST',
        data: requestBody,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        statusCode: response?.status,
      };
    } catch (e) {
      return {
        statusCode: ERROR_STATUS,
      };
    }
  };

  public static checkIsEmailExists = async (email: string) => {
    try {
      const requestBody = {
        email,
      };
      const response = await requester<CheckEmailRequest, CheckEmailResponse>({
        url: API_PATHS.CHECK_EMAIL,
        retries: 1,
        withAccess: false,
        method: 'POST',
        data: requestBody,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        statusCode: response?.status,
        isExists: response?.data?.isExists || false,
        isExistsUsers: response?.data?.isExistsUsers || false,
        isExistsSubs: response?.data?.isExistsSubs || false,
      };
    } catch (e) {
      return {
        statusCode: ERROR_STATUS,
        isExists: true,
        isExistsUsers: false,
        isExistsSubs: false,
      };
    }
  };
}

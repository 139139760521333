import React from 'react';
import './index.css';

export type mainDrawerViewProps = {
  isActive: boolean;
  onClose: (event: any) => void;
};

const MainDrawerView: React.FC<mainDrawerViewProps> = ({ isActive, onClose }) => {
  return (
    <div id="drawer" className={`z-50 drawer_background_blur drawer-fadeout ${isActive ? 'drawer-fadeIn' : 'drawer-fadeOut'}`}>
      <div className="div-block-34 main_drawer">
        <div className="div-block-36 drawer_header">
          <div onClick={onClose} className="drawer_exit_button burger_button_inline">
            <img src="https://uploads-ssl.webflow.com/62f3b57c0b52a65c68135c37/637ba1e44a833882a2a52848_burger%20(1).svg" loading="lazy" width="24" alt="" className="image-39" />
          </div>
        </div>
        <div className="inner_drawer">
          <div className="div-block-35 drawer_inner_content">
            <div className="drawer_inner_item">
              <a
                href="https://www.nection.io/documents/terms-of-use"
                className="drawer_item_link"
              >
                Terms &amp; Conditions
              </a>
            </div>
            <div className="drawer_inner_item">
              <a href="https://www.nection.io/documents/privacy-policy" className="drawer_item_link">
                Privacy
                Policy
              </a>
            </div>
            {/* <div className="drawer_inner_item"> */}
            {/*  <a href="/service/page-subscription-terms" className="drawer_item_link"> */}
            {/*    Subscription */}
            {/*    Policy */}
            {/*  </a> */}
            {/* </div> */}
            <div className="drawer_inner_item">
              <a
                href="mailto:nection.io?subject=Contact%20Nection"
                className="drawer_item_link"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MainDrawerView };

import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICONS_MAIN } from '@utils/constants/icons';
import { TextView } from '@components/texts/TextView';

type backButtonViewProps = {
    showArrow: boolean;
    additionBackButtonPress?: () => void;
};
type backButtonViewState = {};

const BackButtonView: React.FC<backButtonViewProps> = ({ showArrow, additionBackButtonPress }) => {
  const [getState, setState] = useState<backButtonViewState>({});
  const navigator = useNavigate();

  const onPress = useCallback(() => {
    if (navigator.length > 0) {
      additionBackButtonPress && additionBackButtonPress();
      navigator(-1);
    }
  }, [additionBackButtonPress]);

  return (
    <button onClick={onPress} type="button" className="z-10 navigation-button-container text-secondary-t font-primary min-h-[40px] h-full font-normal flex flex-row justify-between items-center button__hovered">
      <>
        {showArrow ? (
          <span className="pt-0.5">
            <img src={ICONS_MAIN.backButtonIcon} alt="BACK_BUTTON_ARROW" />
          </span>
        ) : null}
      </>
      <TextView text="Back" styles="ml-[6.5px]" />
    </button>
  );
};

export { BackButtonView };

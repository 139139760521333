import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './index.css';

type circularProgressViewProps = {
    isStarted: boolean;
    onEndProgress: () => void;

};
type circularProgressViewState = {
    percent: number;
};

const CircularProgressView: React.FC<circularProgressViewProps> = ({ isStarted, onEndProgress }) => {
  const [getState, setState] = useState<circularProgressViewState>({
    percent: 0,
  });

  useEffect(() => {
    if (isStarted) {
      if (getState.percent >= 100) {
        onEndProgress && onEndProgress();
        return;
      }
      setTimeout(() => {
        setState({ ...getState, percent: getState.percent + 1 });
      }, 50);
    }
  }, [isStarted, getState.percent]);

  return (
    <CircularProgressbar
      strokeWidth={10}
      className="w-40 h-40 flex flex-row circular-progress-text"
      value={getState.percent}
      counterClockwise
      text={`${getState.percent}%`}
      styles={buildStyles({
        pathColor: '#1A7AFF',
        textColor: '#1A7AFF',
        trailColor: '#FAF8F2',
        textSize: 18,
      })}
    />
  );
};

export { CircularProgressView };

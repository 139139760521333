import BackButtonIcon from '@assets/icons/BackButtonArrowIcon.svg';
import BurgerButtonIcon from '@assets/icons/BurgerButtonIcon.svg';
import NectionFullLogoIcon from '@assets/icons/NectionFullLogoIcon.svg';
import UserProfileIcon from '@assets/icons/UserProfileIcon.svg';
import NectionLogoDarkIcon from '@assets/icons/NectionLogoDarkIcon.png';
import NectionParrotIcon from '@assets/icons/NectionParrotIcon.svg';
import NectionParrotCoolIcon from '@assets/icons/NectionParrotCoolIcon.svg';
import AnalyzingPreloaderIcon from '@assets/icons/AnalyzingPreloaderIcon.gif';
import PhoneIcon from '@assets/icons/PhoneIcon.png';
import EmailIcon from '@assets/icons/EmailIcon.svg';
import GooglePlayIcon from '@assets/icons/GooglePlayIcon.png';
import AppStoreIcon from '@assets/icons/AppStoreIcon.png';
import CheckboxActiveIcon from '@assets/icons/CheckBox_enabledIcon.svg';
import CheckboxDisabledIcon from '@assets/icons/Checkbox_disabledIcon.svg';
import StarIcon from '@assets/icons/StarIcon.svg';
import AppStoreViewIcon from '@assets/icons/AppStoreViewIcon.svg';
import RedStarIcon from '@assets/icons/RedStarIcon.svg';
import CloseIcon from '@assets/icons/CloseIcon.svg';
import PreloaderIcon from '@assets/icons/PreloaderIcon.gif';
import AppStoreFlatIcon from '@assets/icons/AppStoreInlineIcon.svg';
import PlayMarketFlatIcon from '@assets/icons/PlayMarketFlatIcon.svg';
import CreateAccountIcon from '@assets/icons/CreateAccountIcon.svg';
import SuccessFullCheckIcon from '@assets/icons/SuccessFullCheckIcon.svg';
import PhoneMockupIcon from '@assets/icons/PhoneMockupIcon.svg';
import XMarkIcon from '@assets/icons/XMarkIcon.png';
import NectionFailedSubscription from '@assets/icons/NectionFailedSubscription.svg';

import AssistantIcon from '@assets/icons/content-card/AssistantIcon.png';
import CampaignIcon from '@assets/icons/content-card/CampaignIcon.png';
import CongratsIcon from '@assets/icons/content-card/CongratsIcon.png';
import ContactSliceIcon from '@assets/icons/content-card/ContactSliceIcon.png';
import GoogleIcon from '@assets/icons/content-card/GoogleIcon.png';
import MapIcon from '@assets/icons/content-card/MapIcon.png';
import NotificationIcon from '@assets/icons/content-card/NotificationIcon.png';
import WeatherIcon from '@assets/icons/content-card/WeatherIcon.png';
import ImportContactsIcon from '@assets/icons/content-card/ImportContantsIcon.png';

export const ICONS_MAIN = {
  backButtonIcon: BackButtonIcon,
  burgerButtonIcon: BurgerButtonIcon,
  nectionFullLogoIcon: NectionFullLogoIcon,
  userProfileIcon: UserProfileIcon,
  nectionLogoDarkIcon: NectionLogoDarkIcon,
  nectionParrotIcon: NectionParrotIcon,
  analyzingPreloaderIcon: AnalyzingPreloaderIcon,
  phoneIcon: PhoneIcon,
  emailIcon: EmailIcon,
  googlePlayIcon: GooglePlayIcon,
  appStoreIcon: AppStoreIcon,
  checkboxActiveIcon: CheckboxActiveIcon,
  checkboxDisabledIcon: CheckboxDisabledIcon,
  starIcon: StarIcon,
  appStoreViewIcon: AppStoreViewIcon,
  redStarIcon: RedStarIcon,
  closeIcon: CloseIcon,
  preloaderIcon: PreloaderIcon,
  nectionParrotCoolIcon: NectionParrotCoolIcon,
  appStoreFlatIcon: AppStoreFlatIcon,
  playMarketFlatIcon: PlayMarketFlatIcon,
  createAccountIcon: CreateAccountIcon,
  successFullCheckIcon: SuccessFullCheckIcon,
  phoneMockupIcon: PhoneMockupIcon,
  xMarkIcon: XMarkIcon,
  nectionFailedSubscription: NectionFailedSubscription,
};

export const CONTENT_IMG = {
  assistantIcon: AssistantIcon,
  campaignIcon: CampaignIcon,
  congratsIcon: CongratsIcon,
  contactSliceIcon: ContactSliceIcon,
  googleIcon: GoogleIcon,
  mapIcon: MapIcon,
  notificationIcon: NotificationIcon,
  weatherIcon: WeatherIcon,
  importContactsIcon: ImportContactsIcon,
};

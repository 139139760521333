import React, { useCallback, useState } from 'react';
import { TextView } from '@components/texts/TextView';

type baseButtonViewProps = {
    onPress: () => void;
    disabled?: boolean;
    text: string;
    styles?: string;
    textStyles?: string;
    type?: 'button' | 'submit';
    leftIcon?: string;
};
type baseButtonViewState = {};

const PrimaryButtonView: React.FC<baseButtonViewProps> = ({ leftIcon, type, onPress, styles, disabled, text, textStyles }) => {
  const onButtonPress = useCallback(async () => {
    onPress && onPress();
  }, []);

  return (
    <button className={styles} onClick={onPress} disabled={disabled} type={type || 'button'}>
      {leftIcon && (
      <div>
        <img src={leftIcon} alt="PRIMARY_BUTTON_LEFT_ICON" />
      </div>
      )}
      <TextView text={text} styles={textStyles || ''} />
    </button>
  );
};

export { PrimaryButtonView };

import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { ChooseSubscriptionView } from '@components/layout/ChooseSubscription';
import { ContentCardView } from '@components/layout/ContentCard';
import { DownloadsCardView } from '@components/layout/DownloadsCard';
import { GetPlanMyCardView } from '@components/layout/GetPlanMyCard';
import { mock } from '@typo/pub';
import { motion } from 'framer-motion';
import StatePopupView from '@components/texts/StatePopup';
import CountdownTimer from "@components/CountdownTimer";

export type subscriptionScreenPresenterProps = {};

const SubscriptionScreenPresenter: React.FC<subscriptionScreenPresenterProps> = ({}) => {
  return (
    <motion.div
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton drawerButton personalAccountButton />
        <CountdownTimer />
      </div>
      <PrimaryLayoutView>
        <>
          <ChooseSubscriptionView />
          <ContentCardView
            data={mock.screens.subscription_screen.content_card.data}
            header={mock.screens.subscription_screen.content_card.header}
          />
          <DownloadsCardView
            downloads={mock.screens.subscription_screen.download_card.downloads}
            starsCount={mock.screens.subscription_screen.download_card.starsCount}
          />
          <GetPlanMyCardView />
        </>
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { SubscriptionScreenPresenter };

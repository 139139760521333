import React from 'react';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { SignInFormView } from '@components/layout/SignInForm';
import { SignUpFormView } from '@components/layout/SignUpForm';
import { MainHeaderView } from '@core/navigation/headers/MainHeader/view';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { motion } from 'framer-motion';

export type authScreenPresenterProps = {
  isAuthSelected: boolean;
  setIsAuthSelected: (flag: boolean) => void
};

const AuthScreenPresenter: React.FC<authScreenPresenterProps> = ({ isAuthSelected, setIsAuthSelected }) => {
  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController
          rightButton
          leftButton
          drawerButton
          personalAccountButton={false}
        />
      </div>
      <PrimaryLayoutView>
        {isAuthSelected ? (
          <div>
            <SignInFormView
              onAlreadyHavePress={() => setIsAuthSelected(false)}
            />
          </div>
        )
          : (
            <div>
              <SignUpFormView
                setIsAuthSelected={setIsAuthSelected}
                onNotAccountPress={() => setIsAuthSelected(true)}
              />
            </div>
          )}
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { AuthScreenPresenter };

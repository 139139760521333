import React from 'react';
import './index.css';

type formTextBoxViewProps = {
    leftIcon?: string;
    rightIcon?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    value: string;
    isError?: boolean;
    type: string;
};

const FormTextBoxView: React.FC<formTextBoxViewProps> = ({ type, isError, leftIcon, rightIcon, value, placeholder, onChange }) => {
  return (
    <div className={`${isError ? '' : ''} form-text-box-container`}>
      <>
        {
              leftIcon ? (
                <div className="w-1/12">
                  <img className="w-[25px] h-[25px]" src={leftIcon} alt="INPUT_LEFT_ICON" />
                </div>
              ) : null
          }
      </>
      <div className="w-5/6">
        <input
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event.currentTarget.value)}
          type={type}
          name="form_text"
          className="form-text-box-container__input h-full"
        />
      </div>
      <>
        {
                rightIcon ? (
                  <div className="w-1/12">
                    <img src={rightIcon} alt="INPUT_RIGHT_ICON" />
                  </div>
                ) : null
            }
      </>
    </div>
  );
};

export { FormTextBoxView };

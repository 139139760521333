import { __nec_app__ } from '@core/ApplicationLogic';
import { getEnv } from '@core/env';

function initialCheckoutVisibility() {
  return isOpenCheckoutPopUp ? 'block' : 'none';
}
const bodyElement = document.querySelector('body');
let isOpenCheckoutPopUp = false;

function openCheckout(productId: number, callback: () => Promise<void>) {
  const email = __nec_app__.currentUser.user.email;
  if (!email) {
    return;
  }
  const checkoutUrl = `${getEnv('REACT_APP_PAY_PRO_CHECKOUT')}products[1][id]=${productId}&billing-email=${email}&page-template=${productId}&exfo=742`;
  if (checkoutUrl) {
    isOpenCheckoutPopUp = true;
    const popUp = document.createElement('div');
    popUp.className = 'ppg-checkout-modal';
    popUp.setAttribute(
      'style',
      `z-index: 99999; display: ${
        initialCheckoutVisibility()
      }; background-color: transparent; border: 0px none transparent; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;`,
    );
    const popUpButtonClose = document.createElement('button');
    popUpButtonClose.className = 'ppg-btn-close';
    popUpButtonClose.setAttribute(
      'style',
      'cursor:pointer;position:absolute; top:10px; right:10px; background:transparent; border:none; outline:none; color:#fff; font-size:30px;line-height: 1;',
    );
    popUpButtonClose.innerHTML = '×';
    const popUpHeader = document.createElement('div');
    popUpHeader.className = 'ppg-checkout-modal';
    popUpHeader.setAttribute(
      'style',
      'position:absolute; width: calc(100% - 20px);',
    );
    popUpHeader.appendChild(popUpButtonClose);
    const popUpIframe = document.createElement('iframe');
    popUpIframe.className = 'ppg-frame';
    popUpIframe.frameBorder = '0';
    // @ts-ignore
    popUpIframe.allowtransparency = 'true';
    popUpIframe.src = checkoutUrl;
    // @ts-ignore
    popUpIframe.loading = 'lazy';
    popUpIframe.setAttribute(
      'style',
      'width:100%; height:100%;border:0; overflow-x: hidden; overflow-y: auto;',
    );
    popUp.appendChild(popUpHeader);
    popUp.appendChild(popUpIframe);
    bodyElement!.style.overflow = 'hidden';
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(popUp);
    popUpButtonClose.addEventListener('click', () => {
      isOpenCheckoutPopUp = false;
      callback().then();
      bodyElement!.style.overflow = 'scroll';
      popUp.remove();
    });
  }
}

export { openCheckout };

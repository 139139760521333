import { Analytics } from '@core/Analytics';
import { CurrentUser } from './CurrentUser';

export class ApplicationLogic {
  private readonly _currentUser: CurrentUser;

  private readonly _analytics: Analytics;

  private _currentStep: number;

  constructor() {
    this._currentUser = new CurrentUser();
    this._analytics = new Analytics();
    this._currentStep = 0;
  }

  public onStartApplication = () => {
    try {
      this._currentUser.restore();
    } catch (e) {
      console.warn('onStartApplication.ex', e);
    }
  };

  public get currentStep(): number {
    return this._currentStep;
  }

  public set currentStep(value: number) {
    this._currentStep = value;
  }

  public get analytics(): Analytics {
    return this._analytics;
  }

  public get currentUser() {
    return this._currentUser;
  }
}

export const __nec_app__ = new ApplicationLogic();

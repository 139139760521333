import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { CardView, cardViewProps } from '@components/layout/Card';
import { motion } from 'framer-motion';

export type homeScreenPresenterProps = {
    cardProperties: cardViewProps
};

const HomeScreenPresenter: React.FC<homeScreenPresenterProps> = ({ cardProperties }) => {
  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton={false} drawerButton personalAccountButton />
      </div>
      <PrimaryLayoutView>
        <CardView {...cardProperties} />
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { HomeScreenPresenter };

import React, { useCallback, useEffect, useState } from 'react';
import '@components/layout/Card/index.css';
import { mocked } from '@api/mock';
import { SubscriptionItemView } from '@components/buttons/SubscriptionItem';
import './index.css';
import { TextView } from '@components/texts/TextView';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { SubscriptionItemInfo } from '@type/Models';
import { Request } from '@api/Request';
import { PreloaderView } from '@components/loaders/PreloaderView';
import { openCheckout } from '@screens/PersonalAccountScreen/utils';
import { __nec_app__ } from '@core/ApplicationLogic';
import { useNavigate } from 'react-router-dom';

type chooseSubscriptionViewProps = {};
type chooseSubscriptionViewState = {
    displayedPricing: string;
    products: Array<SubscriptionItemInfo>;
    selectedProduct: null | SubscriptionItemInfo;
    isLoading: boolean;
};

const ChooseSubscriptionView: React.FC<chooseSubscriptionViewProps> = ({}) => {
  const [getState, setState] = useState<chooseSubscriptionViewState>({
    displayedPricing: '',
    products: [],
    selectedProduct: null,
    isLoading: false,
  });
  const isDisabled = getState.selectedProduct === null;
  const navigator = useNavigate();

  const getSubscriptionList = useCallback(async () => {
    setState({ ...getState, isLoading: true });
    const productsData = await Request.getSubscriptionList();
    if (productsData.statusCode === 200) {
      setState({ ...getState, products: productsData.items, displayedPricing: productsData.currency, isLoading: false });
    }
  }, [getState, setState]);

  const setSelectedProduct = useCallback((data: SubscriptionItemInfo) => {
    if (getState.selectedProduct?.productId === data.productId) {
      setState({ ...getState, selectedProduct: null });
    } else {
      setState({ ...getState, selectedProduct: data });
      __nec_app__.analytics.newEvent('Subscription_Select', true, {
        selectedSubscription: data.name || 'Bugged!',
      }).then();
    }
  }, [getState, setState]);

  const onContinueButtonPress = useCallback(() => {
    if (getState.selectedProduct === null) {
      return;
    }
    openCheckout && openCheckout(getState.selectedProduct.productId, async () => {
      const { email, _id } = __nec_app__.currentUser.user;
      if (email) {
        try {
          const subscriptionResponse = await Request.getSubscriptionInfo(email, null);
          if (subscriptionResponse.statusCode === 200 && subscriptionResponse.data.subscriptionProvider) {
            navigator('/after', { replace: true });
          }
        } catch (e) {
          console.warn('openCheckout.ex', e);
        }
      }
    });
  }, [getState]);

  const subscriptionPolicy = () => {
    window.location.href = 'https://www.nection.io/documents/terms-of-use';
  };

  useEffect(() => {
    if (Array.isArray(getState.products) && getState.products[1]?.productId) {
      setSelectedProduct(getState.products[1]);
    }
  }, [getState.products]);

  useEffect(() => {
    getSubscriptionList().then();
  }, []);
  return (
    <div className="card-container">
      <div className="primary_header mb-4">
        <TextView text="Choose your plan" styles="" />
      </div>
      {
            !getState.isLoading ? (
              <div className="form-2 mb-4">
                {getState.products.map((el) => {
                  const key = `${el.productId}`;
                  return (
                    <SubscriptionItemView
                      key={key}
                      subscription={el}
                      currency={getState.displayedPricing}
                      isActive={getState.selectedProduct?.productId === el.productId}
                      setSelectedProduct={() => setSelectedProduct(el)}
                    />
                  );
                })}
              </div>
            ) : (
              <PreloaderView />
            )
        }
      <div className="w-full">
        <PrimaryButtonView
          disabled={isDisabled}
          styles={`primary-button ${isDisabled && 'primary-button__disabled'}`}
          text="Continue"
          textStyles="primary-button-text"
          onPress={onContinueButtonPress}
        />
      </div>
      <div>
        <div className="sign-in__text-agreement mt-2.5">
          <TextView text="By continuing you agre that you will automatically be charged every  selected period of time intil you cancel in your account. Learn more about cancellation and refund policy in" styles="" />
          {' '}
          <span className="underline cursor-pointer" onClick={subscriptionPolicy}><TextView text="Subscription Policy" styles="" /></span>
        </div>
      </div>
    </div>
  );
};

export { ChooseSubscriptionView };

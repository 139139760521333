import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { QuizScreenPresenter, quizScreenPresenterProps } from '@screens/QuizScreen/view';
import { Quiz } from '@type/Quiz';
import { quiz } from '@typo/pub';
import { StepContext } from '@core/context/stepContext';

type quizScreenContainerProps = {
  quizData: Quiz;
};

const QuizScreenContainer: React.FC<quizScreenContainerProps> = ({ quizData }) => {
  const firstRoute = useMemo(() => quizData.pages[0].slug, [quizData]);
  const { step, setStep } = useContext(StepContext);
  const onUpdateStep = useCallback((step: number) => {
    if (step >= quizData.pages.length) {
      return;
    }
    setStep(step + 1);
  }, [quizData.pages.length, setStep]);

  const ViewProps: quizScreenPresenterProps = {
    quizData,
    onQuestionPress: onUpdateStep,
    quiz,
    firstRoute,
    stepState: step,
  };

  return <QuizScreenPresenter {...ViewProps} />;
};

export { QuizScreenContainer };

import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { QuizHeaderView, quizHeaderViewProps } from '@core/navigation/headers/QuizHeader/view';
import { StepContext } from '@core/context/stepContext';

type quizHeaderControllerProps = {
  maxStep: number;
  currentStep: number;
};

const QuizHeaderController: React.FC<quizHeaderControllerProps> = ({ maxStep, currentStep }) => {
  const { step, setStep } = useContext(StepContext);

  const additionBackButtonPress = useCallback(() => {
    const prevStep = currentStep - 1;
    setStep(prevStep);
  }, [currentStep, setStep]);

  const ViewProps: quizHeaderViewProps = {
    leftButton: true,
    maxStep,
    currentStep,
    additionBackButtonPress,
  };

  return <QuizHeaderView {...ViewProps} />;
};

export { QuizHeaderController };

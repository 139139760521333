import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { FormTextBoxView } from '@components/inputs/FormTextBox';
import { ICONS_MAIN } from '@utils/constants/icons';
import { Request } from '@api/Request';
import { __nec_app__ } from '@core/ApplicationLogic';
import StatePopupView, { statePopupViewForward } from '@components/texts/StatePopup';
import { useNavigate } from 'react-router-dom';
import { gTagEventIds } from '@utils/constants/strings';

type signUpFormViewProps = {
    onNotAccountPress: () => void;
    setIsAuthSelected: (flag: boolean) => void;
};

type signUpFormViewState = {
    emailInput: string;
    isDisabled: boolean;
};

const SignUpFormView: React.FC<signUpFormViewProps> = ({ onNotAccountPress, setIsAuthSelected }) => {
  const [getState, setState] = useState<signUpFormViewState>({
    emailInput: '',
    isDisabled: true,
  });
  const statePopupRef = useRef<statePopupViewForward>(null);
  const navigator = useNavigate();

  const termsAndConditions = () => {
    window.location.href = 'https://www.nection.io/documents/terms-of-use';
  };

  const privacyPolicy = () => {
    window.location.href = 'https://www.nection.io/documents/privacy-policy';
  };

  const onPopupButtonPress = useCallback(() => {
    setIsAuthSelected && setIsAuthSelected(true);
  }, [setIsAuthSelected]);

  const onSubmitButton = useCallback(async () => {
    if (!getState.emailInput) {
      return;
    }
    await __nec_app__.analytics.newConversionEvent(gTagEventIds.authScreenId);
    const checkIsEmailExists = await Request.checkIsEmailExists(getState.emailInput.trim());
    if (checkIsEmailExists && checkIsEmailExists.isExistsUsers) {
      if (statePopupRef.current) {
        statePopupRef.current.show('User with this email is already exists!');
      }
      return;
    }
    const response = await Request.getSubscriptionInfo(getState.emailInput, null);
    const isGenerated = await __nec_app__.currentUser.generateTemporaryUser(getState.emailInput);
    if (isGenerated && response?.statusCode === 200) {
      if (response?.data?.subscriptionProvider) {
        navigator('/personal-account', { replace: true });
      } else {
        navigator('/subscriptions', { replace: true });
      }
    }
  }, [getState.emailInput, navigator]);

  useEffect(() => {
    const isValid = /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\\-]+)(\.[a-zA-Z]{2,5}){1,2}/gm.test(getState.emailInput);
    if (isValid) {
      setState({ ...getState, isDisabled: false });
    } else {
      setState({ ...getState, isDisabled: true });
    }
  }, [getState.emailInput]);

  return (
    <div className="sign-up-container centered-container">
      <div className="primary_header mb-2 mt-6">
        <TextView text="Create your profile" styles="" />
      </div>
      <div className="primary_subHeader mb-6">
        <TextView text="Enter your email to create a personal account" styles="" />
      </div>
      <FormTextBoxView leftIcon={ICONS_MAIN.emailIcon} placeholder="Enter your email" value={getState.emailInput} onChange={(val) => setState({ ...getState, emailInput: val })} type="email" />
      <div className="w-full max-w-mob">
        <StatePopupView
          showButton
          buttonText="Log In"
          onButtonPress={onPopupButtonPress}
          ref={statePopupRef}
          fallBackText="This email is already exists"
        />
      </div>
      <div>
        <div className="sign-in__text-agreement my-3">
          By continuing you indicate that you&apos;ve read and agree to our
          {' '}
          <span className="underline cursor-pointer" onClick={termsAndConditions}>Terms & Conditions</span>
          {' '}
          and
          {' '}
          <span className="underline cursor-pointer" onClick={privacyPolicy}>Privacy Policy</span>
        </div>
      </div>
      <div className="w-full">
        <PrimaryButtonView disabled={getState.isDisabled} styles={`primary-button ${getState.isDisabled && 'primary-button__disabled'}`} text="Continue" textStyles="primary-button-text" onPress={onSubmitButton} />
      </div>
      <div className="w-full mt-4 flex flex-row items-center justify-center gap-3">
        <TextView styles="normal-text" text="Already have an account?" />
        <PrimaryButtonView styles="tertiary-button" text="Log In" textStyles="tertiary-button__text text-sm font-bold" type="button" onPress={onNotAccountPress} />
      </div>
    </div>
  );
};

export { SignUpFormView };

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MainDrawerView, mainDrawerViewProps } from '@core/navigation/drawer/MainDrawer/view';

type mainDrawerControllerProps = {
  isActive: boolean;
  onClose: () => void;
};

const MainDrawerController: React.FC<mainDrawerControllerProps> = ({ isActive, onClose }) => {
  const drawer = document.getElementById('drawer');
  const onCloseDrawer = useCallback((event: any) => {
    event.stopPropagation();
    onClose && onClose();
  }, [onClose]);

  const ViewProps: mainDrawerViewProps = {
    isActive,
    onClose: onCloseDrawer,
  };

  return <MainDrawerView {...ViewProps} />;
};

export { MainDrawerController };

import React from 'react';

type imageButtonViewProps = {
    image: string;
    onPress: () => void;
};

const ImageButtonView: React.FC<imageButtonViewProps> = ({ image, onPress }) => {
  return (
    <div onClick={onPress} className="cursor-pointer max-w-full inline-block">
      <img className="w-[131px] h-[43px]" src={image} alt="IMAGE_BUTTON_SOURCE" />
    </div>
  );
};

export { ImageButtonView };

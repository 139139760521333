import React, { useState } from 'react';

type iconButtonViewProps = {
  icon: string;
  onPress: () => void;
  disabled: boolean;
};
type iconButtonViewState = {};

const IconButtonView: React.FC<iconButtonViewProps> = ({ icon, onPress, disabled }) => {
  const [getState, setState] = useState<iconButtonViewState>({});

  return (
    <button type="button" className="navigation-button-container h-full aspect-square button__hovered" disabled={disabled} onClick={onPress}>
      <div className="pt-[5px]">
        <img className="" src={icon} alt="ICON_BUTTON_VIEW" />
      </div>
    </button>
  );
};

export { IconButtonView };

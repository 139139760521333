import React, { useState } from 'react';
import { SubscriptionScreenPresenter, subscriptionScreenPresenterProps } from '@screens/SubscriptionScreen/view';

type subscriptionScreenContainerProps = {};
type subscriptionScreenContainerState = {};

const SubscriptionScreenContainer: React.FC<subscriptionScreenContainerProps> = ({}) => {
  const ViewProps: subscriptionScreenPresenterProps = {};
  return <SubscriptionScreenPresenter {...ViewProps} />;
};

export { SubscriptionScreenContainer };

import React from 'react';
import { Routes } from 'react-router-dom';
import { Navigate, Route } from 'react-router';
import { QuestionScreenContainer } from '@screens/QuestionScreen';
import { Quiz, QuizFile } from '@type/Quiz';
import { motion } from 'framer-motion';
import { QuizHeaderController } from '@core/navigation/headers/QuizHeader';

export type quizScreenPresenterProps = {
  quizData: Quiz;
  onQuestionPress: (step: number) => void;
  quiz: QuizFile
  firstRoute: string;
  stepState: number;
};

const QuizScreenPresenter: React.FC<quizScreenPresenterProps> = ({ stepState, firstRoute, quiz, quizData, onQuestionPress }) => {
  if (!quiz || !quizData) {
    return <></>;
  }
  const headerProps = {
    maxStep: quizData.pages.length,
  };
  return (
    <>
      <QuizHeaderController currentStep={stepState} maxStep={quizData.pages.length} />
      <motion.div
        transition={{ duration: 1 }}
        className="w-full min-h-[90vh] h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Routes>
          {quizData.pages.map((question, index) => {
            return (
              <Route
                key={`${+index}${question.slug}`}
                path={`/${question.slug}`}
                element={(
                  <QuestionScreenContainer
                    currentIndex={index}
                    nextSlug={quizData?.pages[index + 1]?.slug || null}
                    onQuestionPress={onQuestionPress}
                    question={question}
                    quizData={quizData.data}
                  />
        )}
              />
            );
          })}
          <Route path="/" element={<Navigate replace to={firstRoute} />} />
        </Routes>
      </motion.div>

    </>
  );
};

export { QuizScreenPresenter };

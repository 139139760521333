import { TokenData, UserData } from '@type/Models';
import { genRand } from '@utils/utils';

export enum LOCAL_KEYS {
  KEY_USER = '__user__',
  KEY_TOKENS = '__tokens__',
  COUNTDOWN_TIMER = 'countdownTime',
  DEVICE_ID = 'deviceId',
}

interface ICurrentUser {
  isAuth: () => boolean;
  restore: () => boolean;
  saveIntoUser: (user: UserData) => boolean;
  logOut: () => Promise<boolean>;
}

const userEmptyTemplate = {
  pseudoId: '',
  isPremium: {},
  __v: 0,
  _id: null,
  firstName: '',
  lastName: '',
  email: '',
  createdAt: '',
  lastActivityAt: '',
  countryCode: '',
  phone: '',
  rated: false,
  passedOnboarding: false,
  settings: {},
  statistics: {},
};

const tokensEmptyTemplate = {
  accessExpiration: -1,
  accessToken: '',
  refreshToken: '',
};

export class CurrentUser implements ICurrentUser {
  private _user: UserData;

  private _tokens: TokenData;

  constructor() {
    this._user = { ...userEmptyTemplate };
    this._tokens = { ...tokensEmptyTemplate };
  }

  public isMyId = (id: string): boolean => this._user._id === id;

  public isAuth = (): boolean => this._user.email !== '';

  public get user() {
    return this._user;
  }

  public generatePseudoId() {
    if (!this.user.pseudoId) {
      this._user.pseudoId = genRand(24);
      this.saveUser(this.user);
    }
    return this.user.pseudoId;
  }

  public get tokens() {
    return this._tokens;
  }

  private parseLocalStorage = <T>(key: LOCAL_KEYS): T | null => {
    const data = localStorage.getItem(key);
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  };

  public restore = (): boolean => {
    const user = this.parseLocalStorage<UserData>(LOCAL_KEYS.KEY_USER);
    // const tokens = this.parseLocalStorage<TokenData>(LOCAL_KEYS.KEY_TOKENS);
    if (!user) {
      return false;
    }
    if (!user.email) {
      return false;
    }
    this._user = user;
    // this._tokens = tokens;
    return true;
  };

  public saveTokens = (tokens: TokenData) => {
    if (tokens?.accessToken?.length < 50 || !tokens?.accessExpiration) {
      console.warn('something went wrong on parsing data from api');
      return;
    }
    const tokensJSON: string = JSON.stringify(tokens);
    localStorage.setItem(LOCAL_KEYS.KEY_TOKENS, tokensJSON);
  };

  public saveUser = (user: UserData) => {
    if (!user.email) {
      console.warn('something went wrong on parsing data from api');
      return;
    }
    const userJSON: string = JSON.stringify(user);
    localStorage.setItem(LOCAL_KEYS.KEY_USER, userJSON);
  };

  public generateTemporaryUser = async (email: string) => {
    try {
      if (!email) {
        return false;
      }
      await this.logOut();
      this._user.email = email;
      this._user.pseudoId = genRand(14);
      this.saveUser(this._user);
      return true;
    } catch (e) {
      return false;
    }
  };

  public saveIntoUser = (user: UserData) => {
    this.saveUser(user);
    return this.restore();
  };

  public register = async (email: string) => {

  };

  public logOut = async (): Promise<boolean> => {
    try {
      localStorage.removeItem(LOCAL_KEYS.KEY_USER);
      localStorage.removeItem(LOCAL_KEYS.KEY_TOKENS);
      localStorage.removeItem(LOCAL_KEYS.DEVICE_ID);
      localStorage.removeItem(LOCAL_KEYS.COUNTDOWN_TIMER);
      this._user = { ...userEmptyTemplate };
      this._tokens = { ...tokensEmptyTemplate };
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

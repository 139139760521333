import React, { useCallback, useEffect } from 'react';
import { getEnv } from '@core/env';
import { __nec_app__ } from '@core/ApplicationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AfterFailedPaymentScreenPresenter,
  afterFailedPaymentScreenPresenterProps,
} from '@screens/AfterFailedPaymentScreen/view';

type afterFailedPaymentScreenContainerProps = {};

const AfterFailedPaymentScreenContainer: React.FC<afterFailedPaymentScreenContainerProps> = ({}) => {
  const { email } = __nec_app__.currentUser.user;
  const navigator = useNavigate();
  const goToGooglePlay = () => {
    window.location.href = getEnv('REACT_APP_ONE_LINK');
  };

  const goToSubscriptionsList = useCallback(() => {
    navigator('/subscriptions', { replace: true });
  }, [navigator]);

  const ViewProps: afterFailedPaymentScreenPresenterProps = {
    email,
    goToSubscriptionsList,
  };

  useEffect(() => {
    __nec_app__.analytics.newEvent('Subscription_Failed', true).then();
  }, []);

  return <AfterFailedPaymentScreenPresenter {...ViewProps} />;
};

export { AfterFailedPaymentScreenContainer };

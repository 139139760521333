import { __nec_app__ } from '@core/ApplicationLogic';
import { API_PATHS, axiosImpl } from '@api/axiosImpl';
import { sleep } from '@utils/utils';

export interface BaseRequest {}

export interface IRResponse<T> extends Response {
    message: string;
    data?: T | null
}

export interface IRequester<T> {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTION' | 'PATCH';
  data?: T;
  headers?: {[key: string]: string};
  retries: number;
  withAccess: boolean;
}

export const requester = async <T, R extends BaseRequest>({ url, method, data, headers, retries = 3, withAccess }: IRequester<T>): Promise<IRResponse<R> | null> => {
  try {
    if (retries === 0) {
      console.warn('[NEC]Error! Can not perform request to the server api!');
      return null;
    }
    const { accessExpiration, refreshToken } = __nec_app__.currentUser.tokens;
    const now = Date.now();
    const bufferTimeForRefreshToken = 1000 * 60; // One minute
    if (now + bufferTimeForRefreshToken > +accessExpiration && refreshToken && withAccess) {
      const responseToken = await axiosImpl({
        url: API_PATHS.REFRESH_TOKEN,
        data: { refreshToken },
        method: 'POST',
      });
      if (responseToken.data) {
        __nec_app__.currentUser.saveTokens(responseToken.data);
      }
      if (responseToken.status < 204) {
        return await axiosImpl({
          url,
          method,
          data,
          headers,
        } as any);
      }
    }
    return await axiosImpl({
      url,
      method,
      data,
      headers,
    } as any);
  } catch (ex) {
    console.warn('requester.ex', ex);
    const newRetry = retries--;
    // requester({ url, method, body, headers, retries: newRetry, withAccess });
    return null;
  }
};

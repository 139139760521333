import React from 'react';
import { BackButtonView } from '@components/buttons/BackButton';
import { ICONS_MAIN } from '@utils/constants/icons';
import { QuizProgressBarView } from '@components/loaders/QuizProgressBar';

export type quizHeaderViewProps = {
    leftButton: boolean;
    currentStep: number;
    maxStep: number;
    additionBackButtonPress: () => void;
};

const QuizHeaderView: React.FC<quizHeaderViewProps> = ({ additionBackButtonPress, maxStep, currentStep, leftButton }) => {
  return (
    <header className="h-11 px-[4%] pt-[10px] pb-[5px]">
      <QuizProgressBarView max={maxStep} />
      <div className="h-full flex flex-row justify-start items-center">
        <div className="w-[5%] flex flex-row justify-start items-center pr-2">
          {leftButton ? <BackButtonView additionBackButtonPress={additionBackButtonPress} showArrow /> : null}
        </div>
        <div className="w-[90%]">
          <div className="w-full h-full flex flex-row justify-center items-center">
            <img className="w-[102px]" src={ICONS_MAIN.nectionFullLogoIcon} alt="LOGO" />
          </div>
        </div>
        <div className="w-[5%] flex flex-row justify-end text-base font-primary text-secondary-t pb-3 pr-3">
          {currentStep}
          /
          {maxStep}
        </div>
      </div>
    </header>
  );
};

export { QuizHeaderView };

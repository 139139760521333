export const countryCodeRegexp = /^(\+\d{1,5})$/gm;
export const onlyNumbersRegexp = /^[0-9]+$/gm;
export const initialNum = 30;

export const subscriptionProviders = {
  adapty: 'ADAPTY',
  payPro: 'PAY_PRO_GLOBAL',
};
export const subscriptionActionTypes = {
  suspend: 'Suspend',
  renew: 'Renew',
};
export const emptyPersonalTemplate = {
  providerName: '',
  expirationDate: 0,
  currentlyActive: false,
  status: '',
  subscriptionFullData: {},
};

export const subscriptionPeriodDictionary: {[key: string]: number} = {
  Monthly: 4,
  Annually: 52,
  Yearly: 52,
  Weekly: 1,
  Quarterly: 13,
  Year: 52,
  '1 week': 1,
  '4 weeks': 4,
  '12 weeks': 12,
};

export const gTagEventIds = {
  homeScreenId: 'TzygCPq455AYELLClJAp',
  networkingScreenId: 'nfrBCIC555AYELLClJAp',
  authScreenId: 'UZOJCIO555AYELLClJAp',
};

import React, { useCallback, useEffect } from 'react';
import { NetworkingPlanScreenPresenter, networkingPlanScreenPresenterProps } from '@screens/NetworkingPlanScreen/view';
import { useNavigate } from 'react-router-dom';
import { __nec_app__ } from '@core/ApplicationLogic';
import { gTagEventIds } from '@utils/constants/strings';

type networkingPlanScreenContainerProps = {};

const NetworkingPlanScreenContainer: React.FC<networkingPlanScreenContainerProps> = () => {
  const navigator = useNavigate();

  const onGoNextPress = useCallback(async () => {
    await __nec_app__.analytics.newConversionEvent(gTagEventIds.networkingScreenId, () => {}, {
      value: 1.0,
      currency: 'USD',
    });
    if (__nec_app__.currentUser.isAuth()) {
      navigator('/personal-account', { replace: true });
    } else {
      navigator('/auth', { replace: true });
    }
  }, [navigator]);

  const ViewProps: networkingPlanScreenPresenterProps = {
    onGoNextPress,
  };

  useEffect(() => {
    __nec_app__.analytics.newEvent('Quiz_Finished', true).then();
  }, []);

  return <NetworkingPlanScreenPresenter {...ViewProps} />;
};

export { NetworkingPlanScreenContainer };

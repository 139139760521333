import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { mock } from '@typo/pub';
import { AnalyzingCardView } from '@components/layout/AlalyzingCard';
import { motion } from 'framer-motion';

export type analyzingPlanScreenPresenterProps = {};

const AnalyzingPlanScreenPresenter: React.FC<analyzingPlanScreenPresenterProps> = ({}) => {
  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton drawerButton personalAccountButton={false} />
      </div>
      <PrimaryLayoutView>
        <AnalyzingCardView {...mock.screens.analyzing_screen.analyze_card} />
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { AnalyzingPlanScreenPresenter };

import React from 'react';
import { ICONS_MAIN } from '@utils/constants/icons';
import './index.css';

type commentViewProps = {
    image: string;
    header: string;
    subHeader: string;
    text: string;
    starCount: number;
};

const CommentView: React.FC<commentViewProps> = ({ image, starCount, text, subHeader, header }) => {
  const imageURL = require(`../../../assets/icons/content-card/mocks/${image}`);
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      const key = `${header}_commend${i}`;
      stars.push(
        <img
          key={key}
          src={ICONS_MAIN.starIcon}
          loading="lazy"
          alt=""
          className="image-15"
        />,
      );
    }
    return stars;
  };

  return (
    <div data-w-id="a6729bc4-52d2-32fb-abc6-85d90f8af2d9" className="choose_plan_comment_item">
      <div className="choose_plan_comment_item__heading">
        <img
          src={imageURL}
          loading="lazy"
          alt=""
          className="w-[42px] h-[42px]"
        />
        <div>
          <div className="choose_plan_comment_item-heading__header">{header}</div>
          <div className="choose_plan_comment_item-heading__subHeader">{subHeader}</div>
        </div>
      </div>
      <div>
        <div className="choose_plan_comment_item-content__text">
          {text}
        </div>
      </div>
      <div className="choose_plan_comment_item-content__stars mt-2">
        {renderStars()}
      </div>
    </div>
  );
};

export { CommentView };

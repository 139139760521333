import React from 'react';
import { IContentCardItem } from '@src/types/Models';
import './index.css';
import { TextView } from '@components/texts/TextView';
import { CommentView } from '@components/layout/Comment';
import { CommendItem } from '@type/Models';
import { motion } from 'framer-motion';

type contentCardViewProps = {
    header?: string;
    data: Array<IContentCardItem>;
};

const ContentCardView: React.FC<contentCardViewProps> = ({ data, header }) => {
  const renderContentList = () => {
    if (!Array.isArray(data)) {
      return null;
    }
    return data.map((contentItem, index) => {
      const key = `${contentItem.type}_contentCard_${index}`;
      switch (contentItem.type) {
        case 'icon':
          // eslint-disable-next-line no-case-declarations
          const imageURL = require(`../../../assets/icons/content-card/${contentItem.source}`);
          return (
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} key={key} className="w-full content-card-container__image">
              <img className="w-full" src={imageURL} alt="CONTENT_CARD_IMAGE" />
            </motion.div>
          );
        case 'text':
          return (
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} key={key} className="w-full content-card-container__text">
              <span>{contentItem.source as string}</span>
            </motion.div>
          );
        case 'commend':
          return (
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} key={key} className="mb-4">
              <CommentView {...contentItem.source as CommendItem} />
            </motion.div>
          );
        default:
          return <></>;
      }
    });
  };

  return (
    <div className="content-card-container">
      <>
        {header ? (
          <div className="primary_header mb-2">
            <TextView text={header} styles="" />
          </div>
        ) : null}
      </>
      {renderContentList()}
    </div>
  );
};

export { ContentCardView };

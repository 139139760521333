import React from 'react';
import { TextView } from '@components/texts/TextView';

type twoSideTextViewProps = {
    leftText: string;
    rightText: string;
};

const TwoSideTextView: React.FC<twoSideTextViewProps> = ({ leftText, rightText }) => {
  return (
    <div className="w-full flex flex-row justify-between items-center py-3.5">
      <div className="personal-account-info-item__left">
        <TextView styles="" text={leftText} />
      </div>
      <div className="personal-account-info-item__right">
        <TextView styles="" text={rightText} />
      </div>
    </div>
  );
};

export { TwoSideTextView };

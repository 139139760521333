import React, { useState } from 'react';
import { TextView } from '@components/texts/TextView';
import './index.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

export type cardViewProps = {
  buttonText: string;
  icon: string;
  header?: string;
  subHeader?: string;
  onButtonPress?: () => void;
  timer?: () => boolean;
};
type cardViewState = {};

const CardView: React.FC<cardViewProps> = ({ buttonText, header, subHeader, icon, onButtonPress }) => {
  const [getState, setState] = useState<cardViewState>({});

  const handleButtonClick = () => {
    onButtonPress && onButtonPress();
  };

  return (
    <div className="card-container">
      <div>
        {icon ?
          <img className="w-full h-100 floating_animation" src={icon} alt="CARD_ICON" /> : null}
      </div>
      <>
        {header ?
          <TextView text={header} styles="card-header md:text-2xl my-5" /> : null}
      </>
      <>
        {subHeader ?
          <TextView text={subHeader} styles="card-subHeader  mb-5 whitespace-pre-line md:text-base" /> : null}
      </>
      <div className="w-full md:mt-7">
        <PrimaryButtonView
          styles="primary-button"
          text={buttonText}
          textStyles="primary-button-text"
          onPress={handleButtonClick}
        />
      </div>
    </div>
  );
};

export { CardView };

import React, { useEffect, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import { NoticeAlertView } from '@components/texts/NoticeAlert';
import { ICONS_MAIN } from '@utils/constants/icons';
import { PlanGraphView } from '@components/image/PlanGraph';

type personalPlanViewProps = {
  header?: string;
  subHeader?: string;
};
type personalPlanViewState = {
  isLoaded: boolean;
};

const PersonalPlanCardView: React.FC<personalPlanViewProps> = ({ header, subHeader }) => {
  const [getState, setState] = useState<personalPlanViewState>({
    isLoaded: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setState({ ...getState, isLoaded: true });
    }, 500);
  }, []);

  return (
    <div className="card-container">
      <>
        {subHeader ?
          <TextView text={subHeader} styles="card-subHeader mt-5" /> : null}
      </>
      <>
        {header ?
          <TextView text={header} styles="card-header mb-4" /> : null}
      </>
      <div className="px-3">
        <PlanGraphView isLoaded={getState.isLoaded} />
      </div>
      <div className="pt-10 mb-5">
        <NoticeAlertView bgColor="bg-tertiary-b" text="In order to use the app you will need Android or iOS device" icon={ICONS_MAIN.phoneIcon} />
      </div>
    </div>
  );
};

export { PersonalPlanCardView };

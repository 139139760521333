import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { PreloaderTextView } from '@components/texts/PreloaderTextView';
import { CircularProgressView } from '@components/loaders/CircularProgress';
import { TextView } from '@components/texts/TextView';
import { useNavigate } from 'react-router-dom';

type analyzingCardViewProps = {
  header: string;
  texts: Array<string>;
};
type analyzingCardViewState = {
  refs: Array<{
    text: string,
    ref: MutableRefObject<any>
  }>
  usedArray: Array<number>;
  isStarted: boolean;
};

const AnalyzingCardView: React.FC<analyzingCardViewProps> = ({ header, texts }) => {
  const [getState, setState] = useState<analyzingCardViewState>({
    refs: [],
    usedArray: [],
    isStarted: false,
  });
  const navigator = useNavigate();

  const onEndProgress = useCallback(() => {
    setTimeout(() => {
      navigator('/networking', { replace: true });
    }, 1000);
  }, [navigator]);

  useEffect(() => {
    if (getState.usedArray.length <= 0) {
      return;
    }
    setTimeout(() => {
      const rnd = Math.floor(Math.random() * getState.usedArray.length);
      const randomNum = getState.usedArray[rnd];
      const randomElem = getState.refs[randomNum];
      if (randomElem) {
        randomElem.ref.current && randomElem.ref.current.setIsEnded(true);
        setState({ ...getState, usedArray: getState.usedArray.filter((el, index) => index !== rnd) });
      }
    }, 1000);
  }, [getState.usedArray]);

  useEffect(() => {
    const usedArray: number[] = [];
    const arrays = texts.map((el, index) => {
      usedArray.push(index);
      return {
        text: el,
        ref: React.createRef(),
      };
    });
    setState({ ...getState, refs: arrays, usedArray, isStarted: true });
  }, [texts]);

  const renderItems = () => {
    if (!Array.isArray(getState.refs)) {
      return <></>;
    }
    return getState.refs.map((item) => {
      return <PreloaderTextView key={`${item.text}`} {...item} />;
    });
  };
  return (
    <div className="card-container">
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-row justify-center mt-5">
          <CircularProgressView onEndProgress={onEndProgress} isStarted={getState.isStarted} />
        </div>
        <div className="w-full text-center mt-10 mb-5">
          {header ?
            <TextView text={header} styles="card-header" /> : null}
        </div>
        <div className="flex flex-col items-start justify-between gap-3 mb-5">
          {renderItems()}
        </div>
      </div>
    </div>
  );
};

export { AnalyzingCardView };

import React from 'react';
import { QuizQuestion } from '@type/Quiz';
import { QuizButtonView } from '@components/buttons/QuizButton';
import { PrimaryButtonView } from '@components/buttons/BaseButton';

type quizQuestionViewProps = {
  onSelectAnswer: (value: string) => void;
  goNext: (value: string) => void;
  selectedAnswers: Array<string>;
} & QuizQuestion;
const QuizQuestionView: React.FC<quizQuestionViewProps> = ({ header, subHeader, name, horizontal, multiselect, answers, onSelectAnswer, goNext, selectedAnswers }) => {
  const renderAnswers = () => answers.map(({ text, value, icon }, index) => {
    const key = `${text}${index}`;
    return <QuizButtonView icon={icon} key={key} text={text} value={value} onPress={onSelectAnswer} multiselect={multiselect} />;
  });
  return (
    <div className="quiz-container">
      <div>
        <div className="quiz-container__header md:text-3.5xl">
          {`${header}`}
        </div>
        <>
          {subHeader ? (
            <div className="quiz-container__subHeader">
              {`${subHeader}`}
            </div>
          )
            : null}
        </>
      </div>
      <div className="quiz-container-answers">
        {renderAnswers()}
      </div>
      <>
        {multiselect ? (
          <div className="w-full">
            <PrimaryButtonView
              disabled={selectedAnswers.length <= 0}
              textStyles="primary-button-text"
              styles={`
              floated-button
              primary-button
              w-full
              ${selectedAnswers.length <= 0 && 'primary-button__disabled'}`}
              onPress={() => goNext('1')}
              text="Continue"
            />
          </div>
        ) : null}
      </>
    </div>
  );
};

export { QuizQuestionView };

import React, { useCallback, useEffect, useState } from 'react';
import { TextView } from '@components/texts/TextView';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { PhoneNumberInputView } from '@components/inputs/PhoneNumber';
import { useLocation, useNavigate } from 'react-router-dom';
import { Request } from '@api/Request';
import { countryCodeRegexp, onlyNumbersRegexp } from '@utils/constants/strings';

type signInFormViewProps = {
    onAlreadyHavePress: () => void;
};
type signInFormViewState = {
    phoneNumber: string;
    codeNumber: string;
    isDisabledButton: boolean;
};

const SignInFormView: React.FC<signInFormViewProps> = ({ onAlreadyHavePress }) => {
  const [getState, setState] = useState<signInFormViewState>({
    phoneNumber: '',
    codeNumber: '',
    isDisabledButton: true,
  });
  const navigator = useNavigate();

  const onChangeCodeInput = useCallback((value: string) => {
    setState({ ...getState, codeNumber: value });
  }, [getState]);

  const onChangeNumberInput = useCallback((value: string) => {
    setState({ ...getState, phoneNumber: value });
  }, [getState]);

  const setButtonDisabled = (flag: boolean) => {
    if (getState.isDisabledButton === flag) {
      return;
    }
    setState({ ...getState, isDisabledButton: flag });
  };

  const onContinueButtonPress = useCallback(async () => {
    if (getState.phoneNumber && getState.codeNumber) {
      const fullPhoneNumber = `${getState.codeNumber.trim()}${getState.phoneNumber.trim()}`;
      const data = await Request.signIn(fullPhoneNumber);
      console.log(data)
      if (data.statusCode === 200 && data.phoneNumberExists) {
        navigator('/verify', { replace: true, state: { phoneNumber: fullPhoneNumber, initialTime: data.initialTime } });
      }
    }
  }, [getState.codeNumber, getState.phoneNumber]);

  const termsAndConditions = () => {
    window.location.href = 'https://www.nection.io/documents/terms-of-use';
  };

  const privacyPolicy = () => {
    window.location.href = 'https://www.nection.io/documents/privacy-policy';
  };

  useEffect(() => {
    const resultCode = /^(\+\d{1,5})$/gm.test(`${getState.codeNumber.trim()}`);
    const resultNumber = /^[0-9]+$/gm.test(`${getState.phoneNumber.trim()}`);
    setButtonDisabled(resultNumber && resultCode);
  }, [getState.codeNumber, getState.phoneNumber]);

  return (
    <div className="sign-in-container centered-container">
      <div className="primary_header mb-2 mt-6">
        <TextView text="Log In" styles="" />
      </div>
      <div className="primary_subHeader mb-6">
        <TextView text="Enter phone number for your personal account" styles="" />
      </div>
      <div>
        <PhoneNumberInputView phoneNumber={getState.phoneNumber} codeNumber={getState.codeNumber} onCodeChange={onChangeCodeInput} onChangeNumberInput={onChangeNumberInput} />
        <div className="sign-in__text-agreement my-3">
          By continuing you indicate that you&apos;ve read and agree to our
          {' '}
          <span className="underline cursor-pointer" onClick={termsAndConditions}>Terms & Conditions</span>
          {' '}
          and
          {' '}
          <span className="underline cursor-pointer" onClick={privacyPolicy}>Privacy Policy</span>
        </div>
      </div>
      <div className="w-full">
        <PrimaryButtonView
          styles={`primary-button ${!getState.isDisabledButton && 'primary-button__disabled'}`}
          text="Continue"
          textStyles="primary-button-text"
          onPress={onContinueButtonPress}
          disabled={!getState.isDisabledButton}
        />
      </div>
      <div className="w-full mt-4 flex flex-row items-center justify-center gap-3">
        <TextView styles="normal-text" text="Do not have an account?" />
        <PrimaryButtonView text="Sign Up" styles="tertiary-button text-sm font-bold" textStyles="tertiary-button__text" type="button" onPress={onAlreadyHavePress} />
      </div>
    </div>
  );
};

export { SignInFormView };

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PersonalAccountScreenPresenter, personalAccountScreenPresenterProps } from '@screens/PersonalAccountScreen/view';
import { useNavigate } from 'react-router-dom';
import { __nec_app__ } from '@core/ApplicationLogic';
import { Request } from '@api/Request';
import { emptyPersonalTemplate, subscriptionActionTypes } from '@utils/constants/strings';
import { getEnv } from '@core/env';

export type subscriptionState = {
  providerName: string;
  subscriptionFullData: any;
  currentlyActive: boolean;
  expirationDate: number;
  status: string;
};

type personalAccountScreenContainerProps = {};
type personalAccountScreenContainerState = {
  isLoading: boolean;
};

const PersonalAccountScreenContainer: React.FC<personalAccountScreenContainerProps> = ({}) => {
  const [getState, setState] = useState<personalAccountScreenContainerState>({
    isLoading: false,
  });
  const [subscriptionState, setSubscriptionState] = useState<subscriptionState>(emptyPersonalTemplate);
  const navigator = useNavigate();
  const cancelModalRef = useRef<any>(null);
  const renewModalRef = useRef<any>(null);

  const getSubscriptionInfo = useCallback(async () => {
    setSubscriptionState(emptyPersonalTemplate);
    const { _id, email } = __nec_app__.currentUser.user;
    if (!email) {
      navigator('/auth', { replace: true });
      return;
    }
    setState({ ...getState, isLoading: true });
    const subscriptionResponse = await Request.getSubscriptionInfo(email, _id);
    if (subscriptionResponse.statusCode === 200 && subscriptionResponse.data.subscriptionProvider) {
      const fields = subscriptionResponse?.data?.subscriptionInfo;
      setSubscriptionState({
        expirationDate: fields.expirationDate,
        currentlyActive: fields.currentlyActive,
        status: fields.data.status || '',
        subscriptionFullData: subscriptionResponse.data,
        providerName: subscriptionResponse.data.subscriptionProvider });
    }
    setState({ ...getState, isLoading: false });
  }, []);

  const onSubscriptionButtonPress = useCallback(() => {
    navigator('/subscriptions');
  }, [navigator]);

  const onLogoutButtonPress = useCallback(async () => {
    const isExit = await __nec_app__.currentUser.logOut();
    if (isExit) {
      navigator('/auth');
    }
  }, [navigator]);

  const onRenewSubscription = useCallback(async() => {
    try {
      const { email } = __nec_app__.currentUser.user;
      const { statusCode } = await Request.cancelOrRenewSubscription(subscriptionActionTypes.renew, email, 1, '1');
      if (statusCode) {
        await getSubscriptionInfo();
        if (cancelModalRef.current) {
          cancelModalRef.current.close();
        }
      }
    } catch (e) {
      console.warn('onRenewSubscription.ex', e);
    }
  }, [getSubscriptionInfo, cancelModalRef]);

  const onCancelSubscription = useCallback(async (cancelNumber: number, reason: string) => {
    try {
      const { email } = __nec_app__.currentUser.user;
      const { statusCode } = await Request.cancelOrRenewSubscription(subscriptionActionTypes.suspend, email, cancelNumber, reason);
      if (statusCode) {
        getSubscriptionInfo().then();
      }
    } catch (e) {
      console.warn('onRenewSubscription.ex', e);
    }
  }, [getSubscriptionInfo]);

  const onCancelModalOpen = useCallback(() => {
    if (cancelModalRef.current) {
      cancelModalRef.current.open();
    }
  }, [cancelModalRef]);

  const onRenewalModalOpen = useCallback(() => {
    if (renewModalRef.current) {
      renewModalRef.current.open();
    }
  }, [renewModalRef]);

  const goToAppStore = () => {
    window.location.href = getEnv('REACT_APP_ONE_LINK');
  };

  const goToGooglePlay = () => {
    window.location.href = getEnv('REACT_APP_ONE_LINK');
  };

  useEffect(() => {
    getSubscriptionInfo().then();
  }, []);

  const ViewProps: personalAccountScreenPresenterProps = {
    onSubscriptionButtonPress,
    goToAppStore,
    goToGooglePlay,
    onLogoutButtonPress,
    cancelModalRef,
    renewModalRef,
    subscriptionState,
    onCancelModalOpen,
    onRenewalModalOpen,
    onRenewSubscription,
    onCancelSubscription,
    isLoading: getState.isLoading,
  };

  return <PersonalAccountScreenPresenter {...ViewProps} />;
};

export { PersonalAccountScreenContainer };

import React, { useState } from 'react';

type primaryLayoutViewProps = {
  children: JSX.Element
};

const PrimaryLayoutView: React.FC<primaryLayoutViewProps> = ({ children }) => (
  <div className="primary_container_layout">
    {children}
  </div>
);

export { PrimaryLayoutView };

import React from 'react';
import './index.css';
import { ICONS_MAIN } from '@utils/constants/icons';
import { SubscriptionItemInfo } from '@type/Models';
import { TextView } from '@components/texts/TextView';
import { subscriptionPeriodDictionary } from '@utils/constants/strings';

type subscriptionItemViewProps = {
    subscription: SubscriptionItemInfo;
    setSelectedProduct: () => void;
    isActive: boolean;
    currency: string;
};

const SubscriptionItemView: React.FC<subscriptionItemViewProps> = ({ setSelectedProduct, subscription, isActive, currency }) => {
  const getSubsPeriod = () => {
    for (const i in subscriptionPeriodDictionary) {
      if (subscription.name.toLowerCase().includes(i.toLowerCase())) {
        return subscriptionPeriodDictionary[i];
      }
    }
    return 1;
  };
  const weekPeriodPrice = +(subscription.displayPrice / getSubsPeriod()).toFixed(2);
  return (
    <div
      onClick={setSelectedProduct}
      className={`choose_plan_form_item no-text-selection mb-5 ${isActive && 'choose_plan_form_item__active'}`}
    >
      {subscription.name.toLowerCase().includes('12 weeks') && (
        <div className="popular_banner text-sm" style={{backgroundColor: 'rgb(26, 122, 255)'}}>
          Most Popular
        </div>
      )}
      {subscription.name.toLowerCase().includes('year') && (
        <div className='popular_banner text-sm bg-gray-400'>
          Save 50%
        </div>
      )}
      <div className="choose_plan_form_item__inner">
        <img
          src={isActive ? ICONS_MAIN.checkboxActiveIcon : ICONS_MAIN.checkboxDisabledIcon}
          loading="lazy"
          alt=""
        />
        <div>
          <div className="choose-plan-form-inner">
            {subscription.name}
          </div>
          <div className="choose-plan-form-inner__price">
            <div className="choose-plan-form-inner-price__previous">
              {currency}
              {' '}
              {(+subscription.displayPrice * 2 + 0.01).toFixed(2)}
            </div>
            <div className="choose-plan-form-inner-price__current">
              {currency}
              {' '}
              {+subscription.displayPrice.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="choose_plan_form_item-inner__per">
        <div className="choose-plan-form-inner-full__price">
          {currency}
          {' '}
          {weekPeriodPrice}
        </div>
        <div className="choose_plan_form_item_per">
          <div className="choose-plan-form-inner-price__previous mr-1">
            {currency}
            {' '}
            {(+weekPeriodPrice * 2).toFixed(2)}
          </div>
          <TextView text="per week" styles="" />
        </div>
      </div>
    </div>
  );
};

export { SubscriptionItemView };

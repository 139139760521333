import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './index.css';
import { ICONS_MAIN } from '@utils/constants/icons';
import { TextView } from '@components/texts/TextView';

type preloaderTextViewProps = {
    text: string;
};
type preloaderTextViewState = {
    isEnded: boolean;
};

const PreloaderTextView: React.FC<preloaderTextViewProps> = forwardRef<{}, preloaderTextViewProps>(({ text }, ref) => {
  const [getState, setState] = useState<preloaderTextViewState>({
    isEnded: false,
  });

  const setIsEnded = (flag: boolean) => {
    setState({ ...getState, isEnded: flag });
  };

  useImperativeHandle(ref, () => ({
    setIsEnded,
  }));

  return (
    <div className="flex flex-row justify-start items-center gap-5">
      <div className="w-[40px] h-[40px]">
        {getState.isEnded ? (
          <div className="pl-1">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        )
          : (
            <img className="w-[40px] h-[40px]" src={ICONS_MAIN.analyzingPreloaderIcon} alt="PRELOADER_ANIMATION" />
          )}
      </div>
      <div>
        <TextView text={text} styles="text-sm font-primary text-black" />
      </div>
    </div>
  );
});

export { PreloaderTextView };

import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import './index.css';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { TextView } from '@components/texts/TextView';
import { ICONS_MAIN } from '@utils/constants/icons';
import { LabelRadioButtonView } from '@components/buttons/LabelRadioButton';

type cancelSubscriptionModalProps = {
  onCancelSubscription: (cancelNumber: number, reason: string) => Promise<void>;
};
type cancelSubscriptionModalState = {
    isOpened: boolean;
    currentCancellationId: string,
    reasonText: string;
    isButtonDisabled: boolean;
};

type cancelSubscriptionForward = {
  open: () => void;
  close: () => void;
}

const CancelSubscriptionModal: React.ForwardRefRenderFunction<cancelSubscriptionForward, cancelSubscriptionModalProps> = ({ onCancelSubscription }, ref) => {
  const [getState, setState] = useState<cancelSubscriptionModalState>({
    isOpened: false,
    currentCancellationId: '-1',
    reasonText: '',
    isButtonDisabled: true,
  });

  const open = () => {
    setState({ ...getState, isOpened: true });
  };

  const close = () => {
    setState({
      ...getState,
      isOpened: false,
      isButtonDisabled: true,
      currentCancellationId: '-1',
      reasonText: '',
    });
  };

  const onRadioChange = useCallback((value: string) => {
    setState({ ...getState, currentCancellationId: value });
  }, [getState]);

  const onTextAreaChange = useCallback((value: string) => {
    setState({ ...getState, reasonText: value });
  }, [getState]);

  const onButtonPress = useCallback(() => {
    onCancelSubscription && onCancelSubscription(+getState.currentCancellationId, getState.reasonText);
    setTimeout(() => {
      close();
    }, 1000);
  }, [getState.currentCancellationId, getState.reasonText, onCancelSubscription]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  useEffect(() => {
    if (getState.currentCancellationId !== '-1') {
      setState({ ...getState, isButtonDisabled: false });
    } else {
      setState({ ...getState, isButtonDisabled: true });
    }
  }, [getState.currentCancellationId, getState.isOpened]);

  return (
    <Modal
      closeTimeoutMS={500}
      aria={{ modal: false }}
      ariaHideApp={false}
      htmlOpenClassName=""
      onRequestClose={close}
      overlayClassName="modal-bg"
      isOpen={getState.isOpened}
      className="modal-content cancel_subscription_modal_content"
    >
      <div className="flex flex-row justify-between items-center mb-3">
        <TextView styles="primary_header" text="Cancel Subscription" />
        <div className="cursor-pointer" onClick={close}>
          <img src={ICONS_MAIN.closeIcon} alt="CLOSE_BUTTON_ICON" />
        </div>
      </div>
      <div>
        <div className="mb-3">
          <TextView styles="primary_subHeader font-bold" text="What is your reason for cancelation?" />
          <span className="text-base font-primary required-field"> *</span>
        </div>
        <LabelRadioButtonView id="radio_1" name="reasonId" text="The price is too high" value="1" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_2" name="reasonId" text="I no longer need this product" value="2" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_3" name="reasonId" text="I am not satisfied with the product" value="3" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_4" name="reasonId" text="I switched to another product" value="4" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_5" name="reasonId" text="It isn't a priority right now" value="5" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_6" name="reasonId" text="I subscribed by mistake" value="6" onChange={onRadioChange} />
        <LabelRadioButtonView id="radio_7" name="reasonId" text="I am switching to another subscription plan" value="8" onChange={onRadioChange} />
      </div>
      <div>
        <div className="mb-3">
          <TextView styles="primary_subHeader font-bold" text="Please, share your thoughts on how we can be better for you or share more details about your problem" />
        </div>
        <textarea onChange={(event) => onTextAreaChange(event.target.value)} className="cancel_textarea" name="" id="" cols={20} rows={10} />
      </div>
      <div className="mt-4 w-full">
        <PrimaryButtonView disabled={getState.isButtonDisabled} styles={`primary-button max-w-[100%] ${getState.isButtonDisabled && 'primary-button__disabled'}`} text="Unsubscribe" textStyles="primary-button-text" onPress={onButtonPress} />
      </div>
    </Modal>
  );
};

export default React.forwardRef(CancelSubscriptionModal);

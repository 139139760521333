import React, { useState } from 'react';

export const StepContext = React.createContext<{step: number, setStep: Function}>({
  step: 0,
  setStep: () => {},
});

export const ContextProvider = ({ children }: {children: JSX.Element}) => {
  const [step, setStep] = useState(0);

  return (
    <StepContext.Provider value={{ step, setStep }}>
      {children}
    </StepContext.Provider>
  );
};

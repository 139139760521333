import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { __nec_app__ } from '@core/ApplicationLogic';
import { MainHeaderView, mainHeaderViewProps } from './view';

type mainHeaderProps = {
  leftButton: boolean;
  rightButton: boolean;
  drawerButton: boolean;
  personalAccountButton: boolean;
};
type mainHeaderState = {
  isActiveDrawer: boolean;
};

const MainHeaderController: React.FC<mainHeaderProps> = ({ leftButton, rightButton, drawerButton, personalAccountButton }) => {
  const [getState, setState] = useState<mainHeaderState>({
    isActiveDrawer: false,
  });
  const navigator = useNavigate();

  const onPersonalAccountPress = useCallback(() => {
    if (__nec_app__.currentUser.isAuth()) {
      navigator('/personal-account');
    } else {
      navigator('/auth');
    }
  }, [navigator]);

  const onBurgerButtonPress = useCallback((flag: boolean) => {
    setState({ ...getState, isActiveDrawer: flag });
  }, [getState]);

  const ViewProps: mainHeaderViewProps = {
    drawerButton,
    rightButton,
    leftButton,
    personalAccountButton,
    onBurgerButtonPress,
    onPersonalAccountPress,
    isActiveDrawer: getState.isActiveDrawer,
  };

  return <MainHeaderView {...ViewProps} />;
};

export { MainHeaderController };

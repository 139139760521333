import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { PersonalAccountView } from '@components/layout/PersonalAccount';
import { motion } from 'framer-motion';
import CancelSubscriptionModal from '@components/modals/CancelSubscriptionModal';
import { subscriptionState } from '@screens/PersonalAccountScreen';
import RenewSubscriptionModal from '@components/modals/RenewSubscriptionModal';

export type personalAccountScreenPresenterProps = {
    onSubscriptionButtonPress: () => void;
    goToAppStore: () => void;
    goToGooglePlay: () => void;
    onLogoutButtonPress: () => void;
    cancelModalRef: React.MutableRefObject<any>;
    renewModalRef: React.MutableRefObject<any>;
    subscriptionState: subscriptionState;
    onCancelModalOpen: () => void;
    onRenewalModalOpen: () => void;
    onRenewSubscription: () => Promise<void>;
    onCancelSubscription: (cancelNumber: number, reason: string) => Promise<void>;
    isLoading: boolean;
};

const PersonalAccountScreenPresenter: React.FC<personalAccountScreenPresenterProps> = ({
  renewModalRef,
  cancelModalRef,
  subscriptionState,
  onLogoutButtonPress,
  goToGooglePlay,
  goToAppStore,
  onSubscriptionButtonPress,
  onCancelModalOpen,
  onRenewalModalOpen,
  onRenewSubscription,
  onCancelSubscription,
  isLoading,
}) => {
  return (
    <>
      <motion.div
        transition={{ duration: 1 }}
        className="w-full min-h-[100vh] h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <MainHeaderController rightButton leftButton drawerButton personalAccountButton={false} />
        </div>
        <PrimaryLayoutView>
          <PersonalAccountView
            isLoading={isLoading}
            onCancelModalOpen={onCancelModalOpen}
            onRenewalModalOpen={onRenewalModalOpen}
            subscriptionState={subscriptionState}
            onLogoutButtonPress={onLogoutButtonPress}
            goToAppStore={goToAppStore}
            goToGooglePlay={goToGooglePlay}
            onSubscriptionButtonPress={onSubscriptionButtonPress}
          />
        </PrimaryLayoutView>
      </motion.div>
      <CancelSubscriptionModal onCancelSubscription={onCancelSubscription} ref={cancelModalRef} />
      <RenewSubscriptionModal onRenewSubscription={onRenewSubscription} ref={renewModalRef} />
    </>
  );
};

export { PersonalAccountScreenPresenter };

import React, { useCallback, useEffect, useState } from 'react';
import { AuthScreenPresenter, authScreenPresenterProps } from '@screens/AuthScreen/view';
import { useLocation, useNavigate } from 'react-router-dom';
import { __nec_app__ } from '@core/ApplicationLogic';

type authScreenContainerProps = {};
type authScreenContainerState = {
  isAuthSelected: boolean;
};

const AuthScreenContainer: React.FC<authScreenContainerProps> = ({}) => {
  const [getState, setState] = useState<authScreenContainerState>({
    isAuthSelected: false,
  });
  const { state } = useLocation();
  const navigator = useNavigate();

  const setIsAuthSelected = useCallback((flag: boolean) => {
    setState({ ...getState, isAuthSelected: flag });
  }, [getState]);

  useEffect(() => {
    const isAuth = __nec_app__.currentUser.isAuth();
    if (isAuth) {
      navigator('/personal-account', { replace: true });
    }
  }, []);

  const ViewProps: authScreenPresenterProps = {
    isAuthSelected: getState.isAuthSelected,
    setIsAuthSelected,
  };

  return <AuthScreenPresenter {...ViewProps} />;
};

export { AuthScreenContainer };

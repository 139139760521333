import React from 'react';
import { MainHeaderController } from '@core/navigation/headers/MainHeader';
import { PrimaryLayoutView } from '@components/layout/PrimaryLayout';
import { TextView } from '@components/texts/TextView';
import { ContentCardView } from '@components/layout/ContentCard';
import { mock } from '@typo/pub';
import { PersonalPlanCardView } from '@components/layout/PersonalPlanCard';
import { PrimaryButtonView } from '@components/buttons/BaseButton';
import { motion } from 'framer-motion';

export type networkingPlanScreenPresenterProps = {
  onGoNextPress: () => void;
};

const NetworkingPlanScreenPresenter: React.FC<networkingPlanScreenPresenterProps> = ({ onGoNextPress }) => {
  return (
    <motion.div
      transition={{ duration: 1 }}
      className="w-full min-h-[100vh] h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <MainHeaderController rightButton leftButton drawerButton personalAccountButton />
      </div>
      <PrimaryLayoutView>
        <div className="h-full pb-10 flex flex-col items-center">
          <PersonalPlanCardView header="Start networking more efficiently today" subHeader="Your personal networking plan is ready." />
          <div className="max-w-mob text-center my-10">
            <TextView text="How Nection helps with networking:" styles="primary_header" />
          </div>
          <div>
            <ContentCardView data={mock.screens.networking_screen.content_card} />
          </div>
          <div className="w-full max-w-mob">
            <PrimaryButtonView styles="primary-button floated-button" text="Continue" textStyles="primary-button-text" onPress={onGoNextPress} />
          </div>
        </div>
      </PrimaryLayoutView>
    </motion.div>
  );
};

export { NetworkingPlanScreenPresenter };
